import {Battle} from "@/store/battle/types";
import {Creature, WarriorClass} from "@/store/level/types";
import {ArtifactNotification, ForceNotification} from "@/store/artifact/types";
import {Tile} from "@/store/place/types";
import {Task} from "@/store/task/types";
import {WheelContentDto} from "@/store/chest/types";
import {CardNews, CardState} from "@/store/card/types";

export interface PlayerState {
    player: Player | undefined;
    campaignTypes: CampaignType[];
    timeDeltaMs: number;
    gameStart: number;
    artifactIdsToLoad: ArtifactId[];
    artifactImageMaxStair: number;
    needDownload: boolean;
    clientUuid: string;
    prompt: Prompt | undefined;
    header: string | undefined;
    footer: string | undefined;
    admined: boolean;
    version: number;
    remindCampaignRules: boolean;
    unseenCampaign: CampaignType | undefined;
    wheelStatus: WheelStatusDto | undefined;
    wheelAutoRotation: boolean,
    quality: Quality | undefined;
    historyCategory: LogType | undefined;
    forceDescriptions: Map<WarriorClass, ForceDescription> | undefined;
    referralBonus: ReferralBonus | undefined;
    hammerDto: HammerDto | undefined;
    cardState: CardState | undefined;
}

export class Player {
    id!: number;
    name!: string;
    accessLevel!: AccessLevel;
    userSource!: UserSource;
    mailOrPhone!: string;

    maxExp!: number;
    currentExp!: number;
    level!: number;
    stair!: number;
    battleStair!: number;
    bestVictoryStair!: number;
    coordinate!: number;

    gold!: number;
    crystal!: number;
    mithril!: number;
    elixir!: number;
    sulfur!: number;
    tickets!: number;

    goodPoints!: number;
    evilPoints!: number;

    arenaPosition!: number;

    defaultWorld!: DefaultWorld;

    battle!: Battle;

    playerMarkers!: PlayerMarker[];
    scenarioMarkers!: ScenarioMarker[];

    creatures!: Creature[];

    notifications!: ArtifactNotification[];
    forceNotifications!: ForceNotification[];

    tasks!: Task[];

    autoOpenCustomPlaceId: number | undefined;

    healthRecoverAt!: number;

    todayAward!: number;
    tomorrowAward!: number;

    requestSpecialPlaces!: boolean;

    tasksAvailable!: boolean;
    haveCompletedTasks!: boolean;
    haveNewTasks!: boolean;
    newTaskText!: string;

    haveNewMailMessage!: boolean;
    hintState!: HintState;

    companionImage!: string;

    specialMusicKey: string | undefined;
    specialMusicTillCoord: number | undefined;

    moveSpeedMode!: MoveSpeedMode;

    haveNewBadges!: boolean;

    prompt!: Prompt;

    havePurchases!: boolean;

    soundMute!: boolean;

    offer: Offer | undefined;

    arenaOpensAt!: number;
    arenaCurrBattles!: number;
    arenaMaxBattles!: number;

    arenaBattlesCount!: number;

    clanParticipationState!: ClanParticipationState;
    worldType!: WorldType;

    clanId!: number | undefined;
    clanName!: string;

    worldEndCoordinate!: number;

    raidOpensAt!: number;
    promptClan!: boolean;

    blockTillRename!: boolean;

    roadNews!: RoadNews;
    newsFrontVersion!: number;

    creatureNews!: CreatureNews;
    giftNews!: GiftNews;
    notification!: Notification;

    lang!: Lang;

    chestType!: ChestType;
    chestCount!: number;

    interfaceMode!: InterfaceMode;

    haveNewGift!: boolean;

    reloadRequestedVersion!: number;

    metaData!: string;

    versionStart!: number;

    haveBankNews!: boolean
}

export class Offer {
    offerType!: OfferType;
    offerName!: string;
    validTill!: number;
    viewed!: boolean;
}

export class Prompt {
    creatureId!: number;
    artifactId!: number;
    text!: string;
}

export enum Notification {
    NONE = 'NONE',
    BONUS_POINTS = 'BONUS_POINTS'
}

export class Balance {
    gold!: number;
    crystal!: number;
    mithril!: number;
    elixir!: number;
    sulfur!: number;

    tasks!: TaskProgress[];
    haveCompletedTasks!: boolean;
}

export class TaskProgress {
    id!: number;
    progress!: number;
}

export class PlayerMarker{
    playerMarker!: string;
    playerId!: number;
}

export class ScenarioMarker{
    scenarioMarker!: string;
    playerId!: number;
}

export enum OfferType {
    NO_OFFER = "NO_OFFER",
    FIRST_HERO = "FIRST_HERO",
    GREEN_DRAGON_DISCOUNT = "GREEN_DRAGON_DISCOUNT",
    ALL_CREATURES_DISCOUNT = "ALL_CREATURES_DISCOUNT",
    GEMS_X2 = "GEMS_X2",
    MYTH_10 = "MYTH_10",
    MYTH_25 = "MYTH_25",
    MYTH_50 = "MYTH_50",
    MYTH_75 = "MYTH_75",
    SUR_25 = "SUR_25",
    BRAND_DISCOUNT = "BRAND_DISCOUNT",
    BLACK_DRAGON_DISCOUNT = "BLACK_DRAGON_DISCOUNT"
}

export enum CharacterName{
    MAN_COMPANION_1 = "MAN_COMPANION_1",
    WOMAN_COMPANION_1 = "WOMAN_COMPANION_1",
    MAN_COMPANION_2 = "MAN_COMPANION_2",
    WOMAN_COMPANION_2 = "WOMAN_COMPANION_2"
}

export class PlayerInfo {
    player!: Player;
    serverTimeMs!: number;
    artifactIdsToLoad!: ArtifactId[];
    artifactImageMaxStair!: number;
    tiles!: Tile[];
    clientUuid!: string;
    admined!: boolean;
    version!: number;
    quality!: Quality;
    optionalUnseenCampaign!: CampaignType;
    referralBonus!: ReferralBonus;
    hammerDto!: HammerDto;
    cardState!: CardState;
}

export class ArtifactId {
    atlasId!: string;
}

export class ForceDescription {
    warriorClass!: WarriorClass;
    descriptions!: string[];
    shortDescription!: string;
}

export class ReferralBonus {
    startBonus!: number;
    refBonus!: number;
    refClanBonus!: number;
}

export class HammerDto {
    currentCount!: number;
    maxCount!: number;
}

export class ForceShortDescription {
    warriorClass!: WarriorClass;
    shortDescription!: string;
}

export enum Quality {
    AUTO = 'AUTO',
    LOWEST = 'LOWEST',
    LOW = 'LOW',
    STANDARD = 'STANDARD',
    HIGH = 'HIGH'
}

export enum MoveSpeedMode {
    DEFAULT_MODE = "DEFAULT_MODE",
    DOUBLED_MODE = "DOUBLED_MODE",
}

export enum AccessLevel {
    TRIAL = "TRIAL",
    PLAYER = "PLAYER",
    MODERATOR = "MODERATOR",
    ADMIN = "ADMIN",
    GOD = "GOD"
}

export enum UserSource {
    own = "own",
    mail = "mail",
    ya = "ya",
    vk = "vk",
    ok = "ok",
    mystore = "mystore",
    foto = "foto",
    google = "google",
    exe = "exe",
    crazygames = "crazygames",
    gamedistrib = "gamedistrib",
    beeline = "beeline",
    kong = "kong"
}

export enum ClanParticipationState {
    NOT_ENLIGHTENED = 'NOT_ENLIGHTENED',
    AVAILABLE = 'AVAILABLE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    ACCEPTED_TO_CLAN = 'ACCEPTED_TO_CLAN',
    CLAN_MEMBER = 'CLAN_MEMBER'
}

export enum WorldType {
    DEFAULT = 'DEFAULT',
    CLAN_RAID = 'CLAN_RAID'
}

export enum DefaultWorld {
    FORREST = 'FORREST',
    SAND = 'SAND',
    RAINBOW = 'RAINBOW'
}

export enum RoadNews {
    NO_NEWS = 'NO_NEWS',
    PART_2 = 'PART_2',
    CUSTOM = 'CUSTOM',
    ASK_RATE = 'ASK_RATE',
    PART_6 = 'PART_6'
}

export enum AppRateType {
    YANDEX = 'YANDEX',
    ANDROID_GOOGLE = 'ANDROID_GOOGLE'
}

export enum CreatureNews {
    NO_NEWS = 'NO_NEWS',
    RANG = 'RANG',
    FORCES = 'FORCES',
    RANG_AND_FORCES = 'RANG_AND_FORCES',
    FORCES_FOR_40 = 'FORCES_FOR_40',
    FORCES_FOR_500 = 'FORCES_FOR_500',
}

export enum Lang {
    RU = 'RU',
    EN = 'EN',
    ANY = 'ANY'
}

export enum LocationType {
    RU = "RU",
    WORLD = "WORLD"
}

export enum LangDescription {
    EN = 'English',
    // HI = 'हिंदी',
    ES = 'Español',
    // AR = 'عرب',
    PT = 'Português',
    FR = 'Français',
    RU = 'Русский',
    DE = 'Deutsch',
    // JA = '日本語',
    CN = '中国人'
}

export class LangProp {
    langId!: string;
    langDescr!: string;
    available!: boolean;
}

export class OkGroupCampaignDto {
    id!: number;
    start!: string;
    end!: string;
    reward!: number;
    datesMatches!: boolean;
    used!: boolean;
    groupUrl!: string;
    member!: boolean;
}

export class StatusDto {
    usages!: number;
    maxUsages!: number;
    dates!: string;
    details!:string;
    baseGiftAllowed!: boolean;
}

export class WheelStatusDto {
    value!: number;
    valueMax!: number;
    rewards!: WheelContentDto[];
    freeSpins!: number;
    dates!: string;
    ticketsForSpin!: number;
    ticketsMaxOfAllTime!: number;
    player!: Player;
}


export enum CampaignType {
    OK_GROUP = 'OK_GROUP',
    CHEST = 'CHEST',
    CHEST_2 = 'CHEST_2',
    WHEEL = 'WHEEL',
    ARTIFACT_SET_1 = 'ARTIFACT_SET_1',
    NO = 'NO',
    GEMS_PLUS_50 = 'GEMS_PLUS_50',
    HERO = 'HERO',
    SNOW_WORLD = 'SNOW_WORLD',
    BOOSTER = 'BOOSTER',
    PORTAL_DISCOUNT = 'PORTAL_DISCOUNT',
}

export enum GiftNews {
    NO_NEWS = 'NO_NEWS',
    FOTOSTRANA = 'FOTOSTRANA',
    GEMS_30 = 'GEMS_30',
    GEMS_100 = 'GEMS_100',
    GEMS_500 = 'GEMS_500',
    GEMS_1000 = 'GEMS_1000'
}

export enum HintState {
    UNSEEN = 'UNSEEN',
    SEEN = 'SEEN',
    NO_HINT = 'NO_HINT'
}

export enum ChestType {
    SILVER = 'SILVER',
    GOLD = 'GOLD',
    AZURE = 'AZURE',
    LAVA = 'LAVA',
    NONE = 'NONE'
}

export enum LogType {
    TASK = 'TASK',
    ARTIFACT = 'ARTIFACT',
    SHOP = 'SHOP',
    TURN = 'TURN',
    OFFER = 'OFFER',
    CLAN = 'CLAN',
    SCENARIO = 'SCENARIO',
    GEMS = 'GEMS',
    RENAME = 'RENAME',
    ADMIN = 'ADMIN',
    WHEEL = 'WHEEL',
    CHEST = 'CHEST',
    PORTAL = 'PORTAL',
    PLACE = 'PLACE',
    FORCE = 'FORCE',
    CARD = 'CARD'
}

export enum PurchaseType {
    HERO = 'HERO',
    HERO_EXCHANGE = 'HERO_EXCHANGE',
    BOOSTER = 'BOOSTER',
    ARTIFACT_BUY = 'ARTIFACT_BUY',
    ARTIFACT_UPDATE = 'ARTIFACT_UPDATE',
    FORCE_UPDATE = 'FORCE_UPDATE',
    ARTIFACT_SLOT = 'ARTIFACT_SLOT',
    ARTIFACT_SPEEDUP = 'ARTIFACT_SPEEDUP',
    FORCE_SPEEDUP = 'FORCE_SPEEDUP',
    FORCE_BUY = 'FORCE_BUY',
    HERO_RANG_UP = 'HERO_RANG_UP',
    HAMMER = 'HAMMER',
    RENAME = 'RENAME',
    CHANGE_GENDER = 'CHANGE_GENDER',
    PORTAL = 'PORTAL',
    ARENA_CHANGE_OPP = 'ARENA_CHANGE_OPP',
    ARENA_SPEEDUP = 'ARENA_SPEEDUP',
    CLAN_CREATE = 'CLAN_CREATE',
    CLAN_DEPOSIT = 'CLAN_DEPOSIT',
    CLAN_SPEEDUP = 'CLAN_SPEEDUP',
    BUY_GIFT = 'BUY_GIFT',
    RESTORE_HEALTH = 'RESTORE_HEALTH',
    BUY_AVATAR = 'BUY_AVATAR',
    CUSTOM_PLACE = 'CUSTOM_PLACE',
    NOTHING = 'NOTHING',
    OTHER = 'OTHER',
    EVENT_REWARD = 'EVENT_REWARD'
}



export enum MetaDataType {
    APP_METRICA_LOG ='APP_METRICA_LOG'
}


export enum PaymentStatus {
    STARTED = 'STARTED',
    GEMS_GRANTED = 'GEMS_GRANTED',
    GEMS_TAKEN = 'GEMS_TAKEN',
    REFUNDED = 'REFUNDED'
}

export enum Currency {
    DEFAULT = 'DEFAULT',
    USD = 'USD',
    EUR = 'EUR'
}

export enum InterfaceMode {
    ORIGINAL = 'ORIGINAL',
    MODERN = 'MODERN'
}

export enum PayType {
    GEMS = 'GEMS',
    ADV = 'ADV'
}

export class LogDto {
    logType!: LogType;
    logText!: string;
    playerLevel!: number;
    playerStair!: number;
    playerMithril!: number;
    loggedAt!: string;
}

export class PaymentDto {
    status!: PaymentStatus;
    transactionId!: number;
    price!: number;
    currency!: Currency;
    rubTotal!: number;
    rubOur!: number;
    initiatedAt!: string;
    playerLevel!: number;
    playerMithril!:number;
}

export class HistoryDto {
    logs!: LogDto[];
    pageCurr!: number;
    pageMax!: number;
}

export class PaymentsDto {
    payments!: PaymentDto[];
    pageCurr!: number;
    pageMax!: number;
}

export class RestoreOptions {
    price!: number;
    rewardedEnable!: boolean;
    rewardedWatchesToday!: number;
    rewardedWatchesMax!: number;
}

export type LocationIpDto = {
    ip: string;
    ip_version: number;
    response_code: number;
    response_message: string;
}

export type LocationDataDto = {
    ip: string;
    ip_number: string;
    ip_version: number;
    country_name: string;
    country_code2: string;
    isp: string;
    response_code: string;
    response_message: string;
}

export class DelayedChestDto {
    contentId!: string;
    speedupPrice!: number;
}