import {SkillType} from "@/store/place/types";
import {Artifact} from "@/store/artifact/types";
import {ForceUpgrade} from "@/store/force/types";

export interface LevelState {
    creatures: Creature[];
}

export class Creature {
    id!: number;
    name!: string;
    experience!: number;
    skills!: Skill[];
    imageId!: string;
    identifier!: string;
    battleId!: number;
    playerId!: number;
    artifacts!: Artifact[];
    forceUpgrades!: ForceUpgrade[];
    slotsCount!: number;
    temporal!: boolean;
    warriorClass!: WarriorClass;
    combative!: boolean;
    protoStair!: number;
    actualStair!: number;
    forcesV2!: Force[];
    level!: number;
    isGlow: boolean = false;
    rankDescription!: string;

    forces!: WarriorClass; //backward compatibility
}

export class Force {
    warriorClass!: WarriorClass;
    level!: number;
}

export class Skill {
    id!: number;
    skillType!: SkillType;
    valueCurrent!: number;
    valueTotal!: number;
}

export class LevelUpDto{
    upgradeCreaturesIdList!: number[];
    skillType!: SkillType;
    skillDelta!: number;
    skillAddition!: number;
}

export enum WarriorClass {
    BEAST = "BEAST",
    NECRO = "NECRO",
    GIN = "GIN",
    DRAGON = "DRAGON",
    GOLEM = "GOLEM",
    MAG = "MAG",
    HUMAN = "HUMAN",
    PHOENIX = "PHOENIX",
    ASG_PHOENIX = "ASG_PHOENIX",


    DEMON = "DEMON",
    WITCH = "WITCH",
    DRAGONSLAYER = "DRAGONSLAYER",
    DRUID = "DRUID",
    SHAMAN = "SHAMAN",
    SERPENT = "SERPENT",
    TROLL = "TROLL",
    LIZARD = "LIZARD",


    IFRIT = "IFRIT",
    LIFE = "LIFE",
    DEATH = "DEATH",
    WIND = "WIND",
    CHAOS = "CHAOS",
    WALL = "WALL",
    ELF = "ELF",
    VAMPIRE = "VAMPIRE",
    LORD = "LORD",
    LUCK = "LUCK",
    BERSERK = "BERSERK",
    SORCERER = "SORCERER",

    WARLOCK = "WARLOCK",

    TITAN = "TITAN",
    ARCHITITAN = "ARCHITITAN",
    ANGEL = "ANGEL",
    ARCHANGEL = "ARCHANGEL",
    YOTUN = "YOTUN",

    KOSCHEY = "KOSCHEY",
    CYCLOP = "CYCLOP",
    POISON = "POISON",
    KEEPER = "KEEPER",
    SPHINX = "SPHINX",
    NORN = "NORN",
    PROVOKATOR = "PROVOKATOR",
    SHADOW = "SHADOW",
    PRIESTESS = "PRIESTESS",
    AS = "AS",

    EMPTY = "EMPTY",
    //
    // DRAGON_2 = "DRAGON_2",
    // WALL_2 = "WALL_2",
    // WIND_2 = "WIND_2",
    // CHAOS_2 = "CHAOS_2",
    // DRUID_2 = "DRUID_2",
    // WIND_3 = "WIND_3",
    // LIFE_3 = "LIFE_3",
    // DRUID_3 = "DRUID_3",
    // WALL_3 = "WALL_3",
    // DEATH_4 = "DEATH_4",
    // CHAOS_4 = "CHAOS_4",
    // WITCH_4 = "WITCH_4",
    // GOLEM_4 = "GOLEM_4",
    // HUMAN_4 = "HUMAN_4",
    // BERSERK_4 = "BERSERK_4",
    // LIFE_5 = "LIFE_5",
    // WIND_5 = "WIND_5",
    // IFRIT_5 = "IFRIT_5",
    // DRUID_5 = "DRUID_5",
    // DRAGON_5 = "DRAGON_5",
    // CHAOS_5 = "CHAOS_5",
    // BEAST_5 = "BEAST_5",
    // GIN_5 = "GIN_5",
    // MAG_2 = "MAG_2",
    // MAG_5 = "MAG_5",
    // BERSERK_5 = "BERSERK_5",
    // PHOENIX_5 = "PHOENIX_5",
    // NECRO_5 = "NECRO_5",
    // GOLEM_5 = "GOLEM_5",
    // DEATH_5 = "DEATH_5",
    // WALL_5 = "WALL_5",
    // WITCH_5 = "WITCH_5",
    // HUMAN_5 = "HUMAN_5",
    // LUCK_5 = "LUCK_5",
    // SERPENT_5 = "SERPENT_5",
    // WIND_7 = "WIND_7",
    // WALL_7 = "WALL_7",
    // DEATH_7 = "DEATH_7",
    // CHAOS_7 = "CHAOS_7"

}
