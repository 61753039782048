import {Tr} from "@/i18n/i18n";
import {Lang, Player, UserSource} from "@/store/player/types";
import ListenerManager from "@/managers/listener-manager";
import {Http} from "@/constants/http";
import SecurityStore from "@/store/security";
import {BankOption} from "@/store/shop/types";
import CU from "@/utils/c-u";

export class IntegrationUtils {

    private static mystoreRegisterCallback: (uid: string, hash: string) => unknown;

    public static isEnglishServer(): boolean {
        let host = window.location.hostname;
        return host.includes('mirland.mobi');
    }

    public static isOkUser(): boolean {
        let host = window.location.hostname;
        return host.includes('ok.zazer.mobi') || host.includes('otest.zazer');
    }

    public static isVkUser(): boolean {
        let host = window.location.hostname;
        return host.includes('vk.zazer.mobi');
    }

    public static isExeUser(): boolean {
        let host = window.location.hostname;
        return host.includes('exe.zazer.mobi') || host.includes('exe.lgtest.fun');
    }

    public static isBeelineUser(): boolean {
        let host = window.location.hostname;
        return host.includes('beeline.zazer.mobi') || host.includes('beeline.lgtest.fun');
    }

    public static isMystoreUser(): boolean {
        let host = window.location.hostname;
        return host.includes('mystore.zazer.mobi');
    }

    public static isYandexUser(): boolean {
        let host = window.location.hostname;
        return host.includes('ya.zazer.mobi');
    }

    public static isMirland(): boolean {
        let host = window.location.hostname;
        return host.includes('mirland.mobi');
    }

    public static isKongUser(): boolean {
        let host = window.location.hostname;
        return host.includes('kong.mirland.mobi');
    }

    public static isCrazyUser(): boolean {
        let host = window.location.hostname;
        return host.includes('crazy.mirland.mobi');
    }

    public static isFotoUser(): boolean {
        let host = window.location.hostname;
        return host.includes('foto.zazer.mobi');
    }

    public static isI18NEnabled(): boolean {
        let host = window.location.hostname;
        return host.includes('ya.zazer') || host.includes('localhost')
            || host.includes('mystore.zazer') || host.includes('foto.zazer') || host.includes('glass.mobi');
    }

    public static isExternalUser(): boolean {
        let host = window.location.hostname;
        return host.includes('mm.zazer') || host.includes('ok.zazer') || host.includes('ya.zazer')
            || host.includes('vk.zazer') || host.includes('mystore.zazer') || host.includes('foto.zazer')
            || host.includes('otest.zazer') || host.includes('exe.zazer')
            || host.includes('exe.lgtest') || host.includes('beeline.zazer') || host.includes('beeline.lgtest')
            || host.includes('crazy.mirland') || host.includes('kong.mirland');
    }

    public static importPaymentSDK(player: Player) {
        let host = window.location.hostname;
        if (host.includes('ok.zazer.mobi') || host.includes('otest.zazer.mobi') || (!!player && player.userSource === UserSource.ok)) {
            IntegrationUtils.addScript('https://api.ok.ru/js/fapi5.js');
        }
    }

    public static initPaymentAPI(player: Player): void {
        let userSource: UserSource = player.userSource;
        if (userSource === UserSource.ok) {
            IntegrationUtils.initOkAPI();
        } else if (userSource === UserSource.vk) {
            IntegrationUtils.initVkPaymentAPI();
        } else if (IntegrationUtils.isYandexUser()) {
            // Checking old payments:
            // @ts-ignore
            window.ysdk.getPayments({ signed: true }).then((payments: any) => {
                payments.getPurchases().then((purchases: any) => purchases.forEach((purchase: any) => {
                    SecurityStore.buyYandex(purchase.productID, purchases.signature).then((gems: number) => {
                        payments.consumePurchase(purchase.purchaseToken);
                    });
                }));
            });
        } else if (IntegrationUtils.isFotoUser()) {
            var errorCallBack = function() {};
            // @ts-ignore
            Window.client = new fsapi("lglass","5dfd94814b92b3108e0a17a71eb4b6f1");
            // @ts-ignore
            Window.client.init(errorCallBack);
        }
    }

    public static showOkPaymentDialog(price: number, name: string, desc: string, code: string): void {
        // @ts-ignore

        // name,
        // description,
        // code,
        // price,
        // options,
        // attributes,
        // currency,
        // callback,
        // uiConf
        if (typeof FAPI !== 'undefined' && FAPI) {
            // @ts-ignore
            window.API_callback = function (method, result, data) {
                if (result === 'ok') {
                    ListenerManager.callOkGemsCallback();
                } else {
                    console.log("Ok callback - result: " + result);
                }
            }
            // @ts-ignore
            FAPI.UI.showPayment(name, desc, code, price, null, null, "ok", "true");
        } else {
            console.error('OK Payment failed: FAPI is not defined.');
        }
    }

    public static showOkInviteDialog(text:string, refCode:string): void {
        // @ts-ignore

        // text
        // params
        // selected_uids
        if (typeof FAPI !== 'undefined' && FAPI) {
            // @ts-ignore
            FAPI.UI.showInvite(text, "ref=" + refCode);
        } else {
            console.error('OK invite failed: FAPI is not defined.');
        }
    }

    public static showFbShareWindow(player:Player, refCode: string):void{
        let refLink = this.getRefLink(player, refCode);
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(refLink!), '_blank');
    }

    public static showYaPaymentDialog(productId: string, successCallback: (gems: number) => unknown): void {
        // @ts-ignore
        window.ysdk.getPayments({ signed: true }).then((payments: any) => {
            payments.purchase({ id: productId }).then((purchase: any) => {
                SecurityStore.buyYandex(productId, purchase.signature).then((gemsToTake: number) => {
                    payments.consumePurchase(purchase.purchaseToken);
                });
            }).catch((err: any) => {
                console.log('Yandex Payment ERROR ' + err);
            })
        }).catch((err: any) => {
            console.log('Yandex Payment ERROR ' + err);
        });
    }

    public static showVkPaymentDialog(id: string): void {
        // @ts-ignore
        vkBridge.send("VKWebAppShowOrderBox", {type: 'item', item: id});
        // @ts-ignore
        vkBridge.subscribe((e: any) => {
            console.log(e);
        });
    }

    public static showVkInviteDialog(text: string, refCode:string): void {
        // @ts-ignore
        vkBridge.send('VKWebAppShowInviteBox', {requestKey: refCode}).then((data) => {
                // if (data.success) {
                // }
        // @ts-ignore
        }).catch((e) => {
            console.log(e);
        });
    }

    public static showMystorePaymentDialog(id: string, price: number, gemsCount: number): void {
        // @ts-ignore
        Window.mystoreApi.paymentFrame({
            'merchant_param': {
                'amount': price,
                'currency': 'RUB',
                'description': String(gemsCount) + Tr('izumrudov'),
                'item_id': id
            }
        });
    }

    public static showKongregatePaymentDialog(bo: BankOption): void {
        // @ts-ignore
        window.kongregate.mtx.purchaseItemsRemote(bo.id, function(result){{
            // TODO
        }});
    }

    public static showFotoPaymentDialog(bo: BankOption): void {
        // @ts-ignore
        Window.client.event("buyItemCallback", (obj: any) => {
            console.log('Client FS API response');
            console.log(obj);
        }, {
            picUrl: 'https://foto.zazer.mobi/assets/shop/' + bo.imageId + '.png',
            name: bo.title,
            amount: 1,
            itemId: bo.id,
            priceFmCents: bo.price * 100
        });
    }

    public static initOkAPI() {
        // @ts-ignore
        if (typeof FAPI !== 'undefined' && FAPI) {
            // @ts-ignore
            let rParams = FAPI.Util.getRequestParameters();
            // @ts-ignore
            FAPI.init(rParams["api_server"], rParams["apiconnection"],
                function () {
                    console.log(Tr('initsializatsija.proshla.uspeshno'));
                }, function (error: any) {
                    console.log(Tr('oshibka.initsializatsii') + error);
                });

            // @ts-ignore
            window.API_callback = function (method, result, data) {
                if (result === 'ok') {
                    ListenerManager.callOkGemsCallback();
                } else {
                    console.log("Ok callback - result: " + result);
                }
            }
        } else {
            console.error('Failed to init FAPI - OK Payment API');
        }
    }

    private static initVkPaymentAPI() {
        // @ts-ignore
        if (typeof vkBridge !== 'undefined' && vkBridge) {
            // @ts-ignore
            vkBridge.send('VKWebAppInit', {});
        }
    }

    private static addScript(scriptSource: string) {
        var s = document.createElement( 'script' );
        s.setAttribute('src', scriptSource);
        document.body.appendChild(s);
    }

    public static registerMystoreUser(mystoreRegisterCallback: (uid: string, hash: string) => unknown) {
        IntegrationUtils.mystoreRegisterCallback = mystoreRegisterCallback;
        // @ts-ignore
        Window.mystoreApi.registerUser();
    }

    public static initMystore() {
        (function apiHandshake(iframeApi) {
            if (typeof iframeApi === 'undefined') {
                console.log('Cannot find iframeApi function, are we inside an iframe?');
                return;
            }

            var externalApi = null;

            var callbacks = {
                appid: 18842,

                getLoginStatusCallback: function(status: any) {},
                userInfoCallback: function(info: any) {
                    console.log('=== userInfoCallback ===');
                    console.log(info);
                    if (IntegrationUtils.mystoreRegisterCallback) {
                        Http.MYSTORE_UID = info.uid;
                        IntegrationUtils.mystoreRegisterCallback(info.uid, info.hash);
                    }
                },
                userProfileCallback: function(profile: any) {},
                registerUserCallback: function(info: any) {
                    if (info.status === 'error' && info.errcode === 1001) {
                        console.log('User already registered');
                        // @ts-ignore
                        Window.mystoreApi.userInfo();
                    } else if (info.status === 'ok') {
                        console.log('=== registerUserCallback ===');
                        console.log(info);
                        if (IntegrationUtils.mystoreRegisterCallback) {
                            Http.MYSTORE_UID = info.uid;
                            IntegrationUtils.mystoreRegisterCallback(info.uid, info.hash);
                        }
                    } else {
                        console.log('Status is not OK');
                        console.log(info);
                        // @ts-ignore
                        Window.mystoreApi.authUser();
                    }
                },
                paymentFrameUrlCallback: function(url: any) {},
                getAuthTokenCallback: function(token: any) {},
                paymentReceivedCallback: function(data: any) {},
                paymentWindowClosedCallback: function() {},
                userConfirmCallback: function() {},
                paymentFrameItem: function(args: any) {},
                getGameInventoryItems: function() {}
            };

            function error(err: any) {
                throw new Error('Could not init external api ' + err);
            }

            function connected(api: any) {
                externalApi = api;
                // @ts-ignore
                Window.mystoreApi = api;
            }

            iframeApi(callbacks).then(connected, error);
            // @ts-ignore
        }(window.iframeApi));
    }

    public static showOkPostDialog(successCallback: () => unknown): void {
        // @ts-ignore
        if (typeof FAPI !== 'undefined' && FAPI) {
            // @ts-ignore
            window.API_callback = function (method, result, data) {
                if (result === 'ok') {
                    successCallback();
                } else {
                    console.log(result);
                    console.log(data);
                }
            }
            let attachment = {
                "media":[
                    {
                        "type": "text",
                        "text": Tr('intro')
                    },
                    {
                        "type": "app",
                        "images": [
                            {
                                "url": "https://ok.zazer.mobi/assets/banner.jpg",
                                "title": Tr('game.name')
                            }
                        ]
                    }
                ]
            };
            // @ts-ignore
            FAPI.UI.postMediatopic(attachment, false);
        } else {
            console.error('OK Payment failed: FAPI is not defined.');
        }
    }

    public static initExeAPI() {
        // @ts-ignore
        window['handleExeResponse'] = function(functionName: any, result: any) {
            console.log(functionName);
            console.log(result);
        }

        var options = {
            'friends.getAppUsers': 'handleExeResponse',
            'showRequestBox': 'handleExeResponse',
            'showOrderBox': 'handleExeResponse',
            'users.get': 'handleExeResponse'
        };

        // @ts-ignore
        window.exeApi = new ExeRuApi(options);
        // @ts-ignore
        window.exeApi.setHeight(CU.CANVAS_HEIGHT);
    }

    public static showExePaymentDialog(id: string): void {
        // @ts-ignore
        // window.exeApi.users.get({
        //     "fields": "sex,bdate,photo_50"
        // });

        // @ts-ignore
        window.exeApi.showOrderBox({
            "type": "item",
            "item": id,
        });
    }

    public static isClipboardAPIEnabled(): boolean {
        return true;
        // return !this.isYandexUser();
    }

    public static isReferralSystemEnabled() {
        //google, beeline - реализуем позже
        //mail, gamedistrib - неактуальные платформы

        return this.isYandexUser() || this.isVkUser() || this.isOkUser() || this.isExeUser() || this.isCrazyUser() ||
            this.isFotoUser() || this.isMystoreUser() || this.isKongUser() || !this.isExternalUser();
    }

    public static getRefLink(player:Player, refCode:string): string | null {
        switch(player.userSource){
            case UserSource.own:
                if (window.location.hostname.includes("mirland.mobi")) {
                    return 'https://mirland.mobi?ref='+refCode;
                } else if (!window.location.hostname.includes("zazer.mobi")) {
                    let parentUrl = (window.location != window.parent.location)
                        ? document.referrer
                        : document.location.href;
                    let parentHost = parentUrl.indexOf('?') != -1? parentUrl.split('?')[0] : parentUrl;
                    return parentHost + '?ref=' + refCode;
                }
                return 'https://zazer.mobi?ref='+refCode;
            case UserSource.vk:
                return 'https://vk.com/app8110149?request_key=' + refCode;
            case UserSource.ok:
                return 'https://ok.ru/game/lglass?ref=' + refCode;
            case UserSource.kong:
                return 'https://www.kongregate.com/games/hitmakers/mirrorland?ref=' + refCode;
            case UserSource.foto:
                return 'https://fotostrana.ru/app/lglass/?ref=' + refCode;
            case UserSource.mystore:
                return 'https://vkplay.ru/app/18842?ref=' + refCode;
            case UserSource.exe:
                return 'https://exe.ru/app669?request_key=' + refCode;

            case UserSource.crazygames:
            case UserSource.ya:
                let parentUrl = (window.location != window.parent.location)
                    ? document.referrer
                    : document.location.href;
                let parentHost = parentUrl.indexOf('?') != -1? parentUrl.split('?')[0] : parentUrl;
                return parentHost + '?ref=' + refCode;

            //реализуем позже
            case UserSource.google:
            case UserSource.beeline:
            //неактуальные платформы
            case UserSource.gamedistrib:
            case UserSource.mail:
        }

        return null;
    }

    public static getRefCode(): string | null {
        if (!this.isReferralSystemEnabled()){
            return  null;
        }

        if(this.isOkUser()){
            let customArgs = this.getUrlParameter("custom_args");
            return this.getParameterFromEncodedQueryString(customArgs, "ref")
        }

        if(this.isVkUser()){
            // let hash = this.getUrlParameter("hash");
            // return this.getParameterFromEncodedQueryString(hash, "ref")
            return this.getUrlParameter("request_key")
        }

        if(this.isExeUser()){
            return this.getUrlParameter("request_key");
        }

        if(this.isKongUser()){
            this.getParentUrlParameter("ref");
        }

        return this.getUrlParameter("ref");
    }

    public static getUrlParameter(key: string): string | null {
        try {
            return this.doGetUrlParameter(window.location.search, key);
        } catch (e){
            console.error("Error getting url argument " + key)
            return null;
        }
    }

    public static getParentUrlParameter(key: string):string | null{
        try {
            let url = (window.location != window.parent.location) ? document.referrer : document.location.href;
            return this.doGetUrlParameter(url, key);
        } catch (e){
            console.error("Error getting url argument " + key)
            return null;
        }
    }

    private static doGetUrlParameter(url:string, key:string): string | null{
            key = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
            const regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
            const results = regex.exec(url);
            return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    public static getParameterFromEncodedQueryString(encodedParameters:string|null, key: string): string | null {
        try {
            if(!encodedParameters){
                return null;
            }
            let parametersString = decodeURIComponent(encodedParameters);
            let parts = parametersString.split('&');
            for (let part in parts){
                if (part.split("=")[0] == key){
                    return part.split("=")[1];
                }
            }
            return null;
        } catch (e){
            console.error("Error getting parameter from encoded query string:  " + key)
            return null;
        }
    }

    // public static oldCopyToClipboard(text:string) {
    //     navigator.clipboard.writeText(text);
    // }

    public static copyToClipboard(text:string) {
        // Создаем временный элемент для копирования
        const tempInput = document.createElement('textarea');
        tempInput.value = text;
        document.body.appendChild(tempInput);

        // Выделяем текст в элементе
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // Для мобильных устройств

        // Копируем текст в буфер обмена
        document.execCommand('copy');

        // Удаляем временный элемент
        document.body.removeChild(tempInput);
    }
}
