import {Keys} from "@/constants/key";
import {Player} from "@/store/player/types";
import PlayerStore from "@/store/player";
import PlayerUtils from "@/utils/player-utils";

export class MusicUtils {

    public static ROAD_MUSIC_KEYS: string[] = [Keys.MUSIC_SUN_SPARKLING_FOREST];

    public static getRoadMusicKeysToLoad(player: Player): string[] {
        if (!player) {
            return [Keys.MUSIC_SUN_SPARKLING_FOREST];
        }

        if (player.soundMute) {
            return [Keys.MUSIC_SUN_SPARKLING_FOREST];
        }

        let stair = player.stair;

        if (stair <= 3) {
            return [Keys.MUSIC_SUN_SPARKLING_FOREST];
        } else if (stair <= 5) {
            return [Keys.MUSIC_SUN_SPARKLING_FOREST, Keys.MUSIC_ROAD];
        } else if (stair <= 10) {
            return [Keys.MUSIC_SUN_SPARKLING_FOREST, Keys.MUSIC_ROAD, Keys.MUSIC_SERENITY_FOREST];
        } else if (stair <= 15) {
            return [Keys.MUSIC_SUN_SPARKLING_FOREST, Keys.MUSIC_ROAD, Keys.MUSIC_SERENITY_FOREST, Keys.MUSIC_MAGICAL];
        } else {

            if (stair > 500) {
                if (PlayerUtils.hasMarker('ret_f_dark')) {
                    return ['medieval', Keys.MUSIC_SERENITY_FOREST, Keys.MUSIC_MAGICAL, 'desert'];
                } else if (PlayerUtils.hasMarker('been_in_dark')) {
                    return ['desert'];
                } else if (PlayerUtils.hasMarker('p5_completed')) {
                    return [Keys.MUSIC_CELTIC, Keys.MUSIC_ROAD, Keys.MUSIC_SERENITY_FOREST, Keys.MUSIC_MAGICAL, 'egypt2'];
                } else if (PlayerUtils.hasMarker('been_in_gw')) {
                    return [Keys.MUSIC_CELTIC, 'egypt2'];
                } else if (PlayerUtils.hasMarker('p5_in_progress')) {
                    return [Keys.MUSIC_CELTIC, Keys.MUSIC_ROAD, Keys.MUSIC_SERENITY_FOREST, Keys.MUSIC_MAGICAL];
                }
            }

            return [Keys.MUSIC_SUN_SPARKLING_FOREST, Keys.MUSIC_ROAD, Keys.MUSIC_SERENITY_FOREST, Keys.MUSIC_MAGICAL,
                Keys.MUSIC_MAGIC_FOREST, Keys.MUSIC_MAGICAL_FOREST];
        }
    }

}
