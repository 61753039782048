export abstract class Keys {
    static readonly IMG_ARC: string = 'arc';
    static readonly BIG_CAT_MALE: string = 'cat_big_m';
    static readonly BIG_CAT_FEMALE: string = 'cat_big_f';
    static readonly HEROES_COMMENT_3: string = 'heros_comment_3';
    static readonly HEROES_COMMENT_2: string = 'heros_comment_2';
    static readonly HEROES_COMMENT: string = 'heros_comment';
    static readonly BM_COINY: string = 'Coiny';
    static readonly IMG_SPARK1: string = 'spark1';
    static readonly IMG_SPARK2: string = 'spark2';
    static readonly IMG_SPARK3: string = 'spark3';
    static readonly IMG_SNOW: string = 'snow';
    static readonly UI_BG: string = 'ui_Bg';
    static readonly IMG_LUCK_EFFECT_CLOVER: string = 'luck_effect';
    static readonly IMG_GREEN_CONTOUR: string = 'contour_green';
    static readonly IMG_RED_CONTOUR: string = 'contour_red';
    static readonly IMG_BLOCK_CONTOUR: string = 'contour_block';
    static readonly IMG_BLOCK_CONTOUR_GREEN: string = 'contour_block_green';
    static readonly IMG_BLOCK_YELLOW: string = 'cont_bl_yel';
    static readonly IMG_BLOCK_RED: string = 'cont_bl_red';
    static readonly IMG_POISON: string = 'pois';
    static readonly IMG_KOSCHEY: string = 'kosch';
    static readonly FERN_SMALL: string = 'fern-small';
    static readonly IMG_RESURRECT_EFFECT: string = 'resurrect';
    static readonly IMG_ARROW: string = 'purple_arrow';
    static readonly IMG_LEVELUP_ICON: string = 'levelup_icon';
    static readonly IMG_GIVE_UP_FLAG: string = 'give_up_flag';
    static readonly IMG_AFFECT_BACKGROUND: string = 'affect_background';
    static readonly IMG_LOSE_RIBBON: string = 'lose_ribbon';
    static readonly IMG_VICTORY_RIBBON: string = 'victory_ribbon';
    static readonly IMG_LOSE_SKULL: string = 'lose_skull';
    static readonly SKIP_MV: string = 'skip_mv';
    static readonly IMG_VICTORY_KNIGHT: string = 'victory_knight';
    static readonly IMG_GREEN_DOT: string = 'green_dot';
    static readonly IMG_YELLOW_DOT: string = 'yellow_dot';
    static readonly IMG_TICKET: string = 'ticket';
    static readonly IMG_WHITE_SWORD: string = 'white_sword';
    static readonly IMG_WHITE_HEART: string = 'white_heart';
    static readonly IMG_WHITE_SHIELD: string = 'white_shield';
    static readonly IMG_DIALOG_BUBBLE: string = 'dialog_bubble';
    static readonly IMG_TICKET_BIG: string = 'ticket_big';
    static readonly IMG_BATTLE_RESULT_HEARTH: string = 'battle_result_hearth';
    static readonly IMG_ANVIL: string = 'anvil';
    static readonly UI_WINDOW_X_CUTE: string = 'window_x_cute';
    static readonly UI_SCROLL: string = 'scroll';
    static readonly UI_CARD_ATTACK_ICON_GOLD: string = 'card_attack_icon_gold';
    static readonly UI_CARD_DEFENCE_ICON_GOLD: string = 'card_defence_icon_gold';
    static readonly UI_CARD_ATTACK_ICON_GREY: string = 'card_attack_icon_grey';
    static readonly UI_CARD_DEFENCE_ICON_GREY: string = 'card_defence_icon_grey';
    static readonly UI_MENU_BACK: string = 'menu_back';
    static readonly CLAN_SHIELD: string = 'shield';
    static readonly UI_PROFILE_TITLE_LIGHTER: string = 'profile_title_lighter';
    static readonly UI_PROFILE_NAME_CHANGE: string = 'profile_namechange';
    static readonly UI_PROFILE_EXIT: string = 'profile_exit';
    static readonly UI_PROFILE_BOOTS_ON: string = 'profile_boots_on';
    static readonly UI_PROFILE_BOOTS_OFF: string = 'profile_boots_off';
    static readonly UI_PROFILE_HELP: string = 'profile_help';
    static readonly UI_PROFILE_MAIL: string = 'profile_mail';
    static readonly UI_PROFILE_PASSWORD: string = 'profile_passchange';
    static readonly UI_PROFILE_GIFT: string = 'profile_gift';
    static readonly UI_PROFILE_BAN: string = 'profile_ban';
    static readonly UI_PROFILE_SOUND_ON: string = 'profile_sound_on';
    static readonly UI_PROFILE_SOUND_OFF: string = 'profile_sound_off';
    static readonly UI_PROFILE_SOCIAL: string = 'profile_social';
    static readonly UI_PROFILE_LANG: string = 'lang';
    static readonly UI_PROFILE_BLOCK: string = 'profile_block';
    static readonly UI_PROFILE_ELIXIR: string = 'profile_elixir';
    static readonly UI_GENDER_CHANGE: string = 'gender_change';

    static readonly UI_PROFILE_FORCES_INFO_PANEL : string = 'forces-info-panel';
    static readonly UI_PROFILE_FORCES_INFO_PANEL_MIDDLE : string = 'forces-info-panel-middle';
    static readonly UI_PROFILE_FORCES_INFO_PANEL_EDGE : string = 'forces-info-panel-edge';
    static readonly UI_RED_LINER : string = 'red-liner';
    static readonly UI_GOLD_DOT : string = 'gold-dot';
    static readonly UI_PROFILE_FORCES_INFO_PANEL_BOTTOM : string = 'forces-info-panel-bottom';
    static readonly UI_PROFILE_CHAR_BOTTOM_STRIPE : string = 'char-bottom-stripe';
    static readonly UI_PROFILE_CHAR_BOTTOM_STRIPE_2 : string = 'char-bottom-stripe-2';
    static readonly UI_PROFILE_CHAR_BOTTOM_STRIPE_GRAY : string = 'char-bottom-stripe-gray';
    static readonly UI_PROFILE_FORCES_INFO_POINTER : string = 'forces-info-pointer';
    static readonly UI_PROFILE_EVIL_SIGN : string = 'evil-sign';
    static readonly UI_PROFILE_GOOD_SIGN : string = 'good-sign';
    static readonly UI_BLESSING_SIGN : string = 'blessing';
    static readonly UI_PROFILE_EVIL_SIGN_BIG : string = 'evil-sign-big';
    static readonly UI_PROFILE_GOOD_SIGN_BIG : string = 'good-sign-big';
    static readonly UI_GLASS : string = 'ui-glass';
    static readonly UI_COPY : string = 'ui-copy';
    static readonly UI_SHARE : string = 'ui-share';
    static readonly UI_SHARE_OK : string = 'ui-share-ok';
    static readonly UI_SHARE_VK : string = 'ui-share-vk';
    static readonly UI_SHARE_FB : string = 'ui-share-fb';

    static readonly EMPTY : string = 'empty';

    static readonly ICON_HEROES : string = 'icon-heroes';
    static readonly ICON_CARDS : string = 'icon-cards';
    static readonly CARD_ELEPHANT : string = 'card-elephant';
    static readonly CARD_ELEPHANT_SMALL : string = 'card-elephant-small';

	static readonly TASK_BIRTHDAY_DRONT : string = 'task-birthday-dront';
    static readonly TASK_BIRTHDAY_QUEEN : string = 'task-birthday-queen';
    static readonly UI_PAYMENT_CHANGE: string = 'ui_wallet';
    static readonly UI_UI_CHANGE: string = 'ui_mode';
    static readonly UI_PUSH_CHANGE: string = 'push_mode';
    static readonly UI_QA_CHANGE: string = 'qa_mode';
    static readonly UI_BADGE_LINER: string = 'badge_liner';
    static readonly UI_DAMAGE_HEARTH: string = 'damage_hearth';
    static readonly UI_DAMAGE_SHIELD: string = 'damage_shield';
    static readonly UI_DAMAGE_SWORD: string = 'damage_sword';
    static readonly UI_EXIT_DOOR: string = 'exit_door';
    static readonly IMG_ATTACK: string = 'attack';
    static readonly IMG_DEFENCE: string = 'defence';
    static readonly IMG_HEART_ICON: string = 'heart_icon';
    static readonly UI_BUTTON_BROWN: string = 'brown_button';
    static readonly UI_BUTTON_BROWN_SMALL: string = 'brown_button_s';
    static readonly UI_BUTTON_ADD_TO_SQUAD: string = 'button_add_to_squad';
    static readonly UI_SQUARE_FRAME: string = 'square_frame';
    static readonly UI_ARROW: string = 'arrow';
    static readonly UI_ARROW_LEFT: string = 'arr_left';
    static readonly UI_ARROW_RIGHT: string = 'arr_right';
    static readonly UI_ARROW_UP: string = 'arr_up';
    static readonly UI_ARROW_DOWN: string = 'arr_down';
    static readonly UI_HEALTH_BAR_INNER: string = 'health_bar_inner';
    static readonly UI_HEALTH_BAR_OUTER: string = 'health_bar_outer';
    static readonly UI_KOSCHEY_BAR_OUTER: string = 'koschey_bar_outer';
    static readonly UI_BLACK: string = 'black';
    static readonly UI_CONS_SLOT_BACKGROUND: string = 'cons_slot_background';
    static readonly UI_CONS_SLOT_BORDER: string = 'cons_slot_border';
    static readonly UI_CONS_PANEL_BACKGROUND: string = 'cons_panel_background';
    static readonly UI_CONS_EFFECT_ATTACK: string = 'cons_effect_attack';
    static readonly UI_CONS_EFFECT_DEFENCE: string = 'cons_effect_defence';
    static readonly UI_CONS_EFFECT_HEALTH: string = 'cons_effect_health';
    static readonly UI_ART_GIVE_UP_BUTTON: string = 'give_up_button';
    static readonly UI_WHITE_PLUS: string = 'white_plus';
    static readonly UI_WHITE_MINUS: string = 'white_minus';
    static readonly UI_GOLDEN_CHECK: string = 'golden_check';
    static readonly UI_NOTIF_PANEL: string = 'notif_panel';
    static readonly UI_LOCK: string = 'lock';
    static readonly UI_DECLINE_CROSS: string = 'decline_cross';
    static readonly UI_MAILIK: string = 'mailik';
    static readonly UI_SCENARIO_RIBBON: string = 'scenario_ribbon';
    static readonly UI_DAILY_RIBBON: string = 'daily_ribbon';
    static readonly UI_SCENARIO_TASK_BACK: string = 'scenario_task_back';
    static readonly UI_TASK_PROGRESS_BAR_INNER: string = 'task_bar_inner';
    static readonly UI_TASK_PROGRESS_BAR_OUTER: string = 'task_bar_outer';
    static readonly UI_TASK_TITLE_FLASH: string = 'title_flash';
    static readonly UI_STAT_MINE_OVAL: string = 'stat_mine_oval';
    static readonly UI_STAT_ENEMY_OVAL: string = 'stat_enemy_oval';
    static readonly UI_STAT_MINE_CIRCLE: string = 'stat_mine_circle';
    static readonly UI_STAT_WINDOW: string = 'stat_window2';
    static readonly UI_STAT_WINDOW_CORNER: string = 'stat_window_corner';
    static readonly UI_STAT_WINDOW_SEPARATOR: string = 'stat_window_separator';
    static readonly UI_STAT_WINDOW_BACK: string = 'stat_window_back';
    static readonly UI_BOX: string = 'box';
    static readonly UI_CHECK: string = 'check';
    static readonly UI_BORDER: string = 'border';
    static readonly HEALTH_TIMER_BACK: string = 'health_timer_back';
    static readonly GREEN_PLUS: string = 'green_plus';
    static readonly ARROW_CROSS: string = 'arrow_cross';
    static readonly UI_XSOLLA_LOGO: string = 'xsolla-logo';
    static readonly UI_ROBOKASSA_LOGO: string = 'robokassa-logo';

    static readonly NEW_GIFT: string = 'new_gift';

    static readonly UI2_ARENA: string = 'arena';
    static readonly UI2_ARENA_GR: string = 'arena_gr';
    static readonly UI2_BANK: string = 'bank';
    static readonly UI2_CHAT: string = 'chat';
    static readonly UI2_CLAN: string = 'clan';
    static readonly UI2_CLAN_GR: string = 'clan_gr';
    static readonly UI2_CRYSTAL: string = 'crystal';
    static readonly UI2_ELIXIR: string = 'elixir';
    static readonly UI2_GEM: string = 'gem';
    static readonly UI2_GOLD: string = 'gold';
    static readonly UI2_MAIL: string = 'mail';
    static readonly UI2_PLUS: string = 'plus';
    static readonly UI2_PROFILE: string = 'profile';
    static readonly UI2_RATING: string = 'rating';
    static readonly UI2_RES: string = 'res';
    static readonly UI2_SHOP: string = 'shop';
    static readonly UI2_FORUM: string = 'forum';
    static readonly UI2_EXP_BACK: string = 'exp_back';
    static readonly UI2_EXP_INNER: string = 'exp_inner';
    static readonly UI2_EXP_GLASS: string = 'exp_glass';
    static readonly UI2_TASKS: string = 'tasks';

    static readonly FRM_RADIO_OUT: string = 'radio_out';
    static readonly FRM_RADIO_IN: string = 'radio_in';
    static readonly FRM_UP: string = 'frm_up';
    static readonly FRM_UP_GREEN: string = 'frm_up_grn';
    static readonly FRM_TAG_BG: string = 'frm_tag_bg';
    static readonly FRM_CLOSE: string = 'frm_close';
    static readonly FRM_PIN_GREEN: string = 'frm_pin_green';
    static readonly FRM_PIN_ROUND: string = 'frm_pin_round';
    static readonly FRM_COPY_ROUND: string = 'frm_copy_round';
    static readonly FRM_PIN_WHITE: string = 'frm_pin_white';
    static readonly FRM_REPLY_ARROW: string = 'frm_reply_arrow';
    static readonly FRM_PAGING_BUTTON: string = 'frm_paging_button';
    static readonly FRM_PAGING_BUTTON2: string = 'frm_paging_button2';

    static readonly SOUND_GAIN_LOOT: string = 'gain_loot';
    static readonly SOUND_HORSE_WALK: string = 'horse_walk';
    static readonly SOUND_DEFEAT: string = 'sound_defeat';
    static readonly SOUND_VICTORY: string = 'sound_victory';
    static readonly SOUND_VICTORY_2: string = 'level_won';
    static readonly SOUND_PLACE_OPEN: string = 'sound_place_open';
    static readonly SOUND_WIND_UP: string = 'sound_wind_up';
    static readonly SOUND_WIND_DOWN: string = 'sound_wind_down';
    static readonly SOUND_ENEMY_PUNCH_1: string = 'enemy-punch-1';
    static readonly SOUND_ENEMY_PUNCH_2: string = 'enemy-punch-2';
    static readonly SOUND_ENEMY_PUNCH_3: string = 'enemy-punch-3';
    static readonly SOUND_PLAYER_PUNCH_1: string = 'player-punch-1';
    static readonly SOUND_PLAYER_PUNCH_2: string = 'player-punch-2';
    static readonly SOUND_PLAYER_PUNCH_3: string = 'player-punch-3';
    static readonly SOUND_AWARD: string = 'sound_award';
    static readonly SOUND_BUTTON_CLICK: string = 'sound_button_click';
    static readonly SOUND_CREATURE_CLICK: string = 'sound_creature_click';
    static readonly SOUND_BUILDING: string = 'sound_building';
    static readonly SOUND_SELECT: string = 'sound_select';
    static readonly SOUND_COMPLETED: string = 'completed';
    static readonly SOUND_LEVEL_UP: string = 'level-up';
    static readonly SOUND_USE_HEAL_POTION: string = 'use_heal_potion';
    static readonly SOUND_USE_SCROLL: string = 'use_scroll';
    static readonly SOUND_RESURRECT: string = 'resur_sound';
    static readonly SOUND_HELP_MAN_RU: string = 'help_man_ru';
    static readonly SOUND_HELP_WOM_RU: string = 'help_wom_ru';
    static readonly SOUND_HELP_MAN_EN: string = 'help_man_en';
    static readonly SOUND_HELP_WOM_EN: string = 'help_wom_en';

    static readonly MUSIC_ROAD: string = 'main-theme';
    static readonly MUSIC_MAGICAL: string = 'magical';
    static readonly MUSIC_MAGIC_FOREST: string = 'magic-forest';
    static readonly MUSIC_MAGICAL_FOREST: string = 'magical-forest';
    static readonly MUSIC_SERENITY_FOREST: string = 'serenity-forest';
    static readonly MUSIC_SUN_SPARKLING_FOREST: string = 'sun-sparkling-forest';
    static readonly MUSIC_CELTIC: string = 'celtic';

    static readonly MUSIC_CLAN: string = 'music_clan';

    static readonly MUSIC_FANTASY_SNEAK: string = 'fantasy-sneak';
    static readonly MUSIC_THE_MEDIEVAL: string = 'the_medieval';
    static readonly MUSIC_BATTLE: string = 'battle';
    static readonly MUSIC_BATTLE_2: string = 'battle_2';
    static readonly MUSIC_BATTLE_3: string = 'battle_3';
    static readonly MUSIC_BATTLE_4: string = 'military';

    public static readonly ANIM_KNIGHT_WALK_M: string = 'knight_walk_m';
    public static readonly ANIM_KNIGHT_IDLE_M: string = 'knight_idle_m';

    public static readonly ANIM_KNIGHT_WALK_W: string = 'knight_walk_w';
    public static readonly ANIM_KNIGHT_IDLE_W: string = 'knight_idle_w';

    static readonly IMG_RES_GEM_NO_SHADOW: string = 'res_gem_no_shadow';

    static readonly STAIRS_GREEN: string[] = ['green_1_100', 'green_101_200', 'green_201_300', 'green_301_400', 'green_401_500',
        'green_501_600', 'green_601_700', 'green_701_800', 'green_801_900', 'green_901_1000',
        'green_1001_1100','green_1101_1200','green_1201_1300','green_1301_1400','green_1401_1500',
        'green_1501_1600','green_1601_1700','green_1701_1800','green_1801_1900','green_1901_2000',
        'green_2001_2100','green_2101_2200','green_2201_2300','green_2301_2400','green_2401_2500'];

    static readonly STAIRS_YELLOW: string[] = ['yellow_1_100', 'yellow_101_200', 'yellow_201_300', 'yellow_301_400', 'yellow_401_500',
        'yellow_501_600', 'yellow_601_700', 'yellow_701_800', 'yellow_801_900', 'yellow_901_1000',
        'yellow_1001_1100','yellow_1101_1200','yellow_1201_1300','yellow_1301_1400','yellow_1401_1500',
        'yellow_1501_1600','yellow_1601_1700','yellow_1701_1800','yellow_1801_1900','yellow_1901_2000',
        'yellow_2001_2100','yellow_2101_2200','yellow_2201_2300','yellow_2301_2400','yellow_2401_2500'];

    static readonly STAIRS_RED: string[] = ['red_1_100', 'red_101_200', 'red_201_300', 'red_301_400', 'red_401_500',
        'red_501_600', 'red_601_700', 'red_701_800', 'red_801_900', 'red_901_1000',
        'red_1001_1100','red_1101_1200','red_1201_1300','red_1301_1400','red_1401_1500',
        'red_1501_1600','red_1601_1700','red_1701_1800','red_1801_1900','red_1901_2000',
        'red_2001_2100','red_2101_2200','red_2201_2300','red_2301_2400','red_2401_2500'];

    static readonly BRIDGE: string = 'bridge';
    static readonly GATE_1: string = 'gate_1';
    static readonly GATE_2: string = 'gate_2';
    static readonly ARCH_1: string = 'arch_1';
    static readonly ARCH_2: string = 'arch_2';

    static readonly PLACE_SHADOW: string = 'shadow';

    static readonly RAB_RU: string = 'rab_ru';
    static readonly RAB_EN: string = 'rab_en';

    static readonly PROFILE_BACK: string = 'profile_back';
    static readonly SHOP_BACK: string = 'shop_back';
    static readonly TASK_BACK: string = 'task_back';
    static readonly TASK_BOTTOM: string = 'task_bottom';
    static readonly SHOP_BOTTOM: string = 'shop_bottom';

    static readonly IMG_RES_GOLD: string = 'res_gold';
    static readonly IMG_RES_GOLD_NO_SHADOW: string = 'res_gold_no_shadow';
    static readonly IMG_RES_CRYSTAL: string = 'res_crystal';
    static readonly IMG_RES_ELIXIR: string = 'res_elixir';
    static readonly IMG_RES_ELIXIR_NO_SHADOW: string = 'res_elixir_no_shad';
    static readonly IMG_RES_SULFUR: string = 'res_sulfur';
    static readonly IMG_RES_SULFUR_UI: string = 'res_sulfur_ui';
    static readonly IMG_RES_MITHRIL: string = 'res_mithril';
    static readonly IMG_CLICK_FLASH: string = 'click_flash';
    static readonly IMG_CROSSHAIR: string = 'crosshair';
    static readonly IMG_CROSS_SNOW: string = 'cross_snow';
    static readonly CLOUD: string = 'cldd';
    static readonly CLOUD2: string = 'cldd2';

    static readonly IMG_REWARD_CUPS: string = 'br_cups';
    static readonly IMG_REWARD_EXPERIENCE: string = 'br_experience';
    static readonly IMG_REWARD_CRYSTAL_YELLOW: string = 'br_crystal_yellow';
    static readonly IMG_REWARD_CRYSTAL_RED: string = 'br_crystal_red';
    static readonly IMG_REWARD_GOLD: string = 'br_gold';
    static readonly IMG_REWARD_GEM: string = 'br_gem';

    static readonly KM_HEAD: string = 'km_head';
    static readonly ARENA_HEAD: string = 'ar_head';
    static readonly ARENA_BACK_MAIN: string = 'ar_back_main';
    static readonly ARENA_BACK_SECOND: string = 'ar_back_sec';
    static readonly ARENA_CUP: string = 'ar_cup';
    static readonly ARENA_HISTORY: string = 'ar_history';
    static readonly ARENA_REFRESH: string = 'ar_refresh';
    static readonly ARENA_TO_FIGHT: string = 'ar_fight';
    static readonly ARENA_HEART: string = 'ar_heart';
    static readonly ARENA_QUESTION: string = 'ar_question';
    static readonly DIVIDER_VERTICAL: string = 'divider-vert';

    static readonly ATLAS_LEAGUE_SMALL: string = 'atlas_league_small';
    static readonly ATLAS_LEAGUE: string = 'atlas_league';

    static readonly LEAGUE_1: string = 'league-icon-1';
    static readonly LEAGUE_2: string = 'league-icon-2';
    static readonly LEAGUE_3: string = 'league-icon-3';
    static readonly LEAGUE_4: string = 'league-icon-4';
    static readonly LEAGUE_5: string = 'league-icon-5';
    static readonly LEAGUE_6: string = 'league-icon-6';
    static readonly LEAGUE_7: string = 'league-icon-7';
    static readonly LEAGUE_8: string = 'league-icon-8';
    static readonly LEAGUE_9: string = 'league-icon-9';

    static readonly LEAGUE_SHADOW: string = 'league-icon-small-shadow';
    static readonly LEAGUE_1_SMALL: string = 'league-icon-small-1';
    static readonly LEAGUE_2_SMALL: string = 'league-icon-small-2';
    static readonly LEAGUE_3_SMALL: string = 'league-icon-small-3';
    static readonly LEAGUE_4_SMALL: string = 'league-icon-small-4';
    static readonly LEAGUE_5_SMALL: string = 'league-icon-small-5';
    static readonly LEAGUE_6_SMALL: string = 'league-icon-small-6';
    static readonly LEAGUE_7_SMALL: string = 'league-icon-small-7';
    static readonly LEAGUE_8_SMALL: string = 'league-icon-small-8';
    static readonly LEAGUE_9_SMALL: string = 'league-icon-small-9';

    static readonly IMG_PARTICLE_CIRCLE: string = 'circle';

    static readonly CHEST_GOLDEN_CLOSED: string = 'ch_gld_cls';
    static readonly CHEST_GOLDEN_OPENED: string = 'ch_gld_opn';
    static readonly CHEST_SILVER_CLOSED: string = 'ch_slv_cls';
    static readonly CHEST_SILVER_OPENED: string = 'ch_slv_opn';
    static readonly CHEST_AZURE_CLOSED: string = 'ch_azr_cls';
    static readonly CHEST_AZURE_OPENED: string = 'ch_azr_opn';
    static readonly CHEST_LAVA_CLOSED: string = 'ch_lv_cls';
    static readonly CHEST_LAVA_OPENED: string = 'ch_lv_opn';
    static readonly CHEST_GOLDEN_IC: string = 'ch_gld_ic';
    static readonly CHEST_SILVER_IC: string = 'ch_slv_ic';
    static readonly CHEST_AZURE_IC: string = 'ch_az_ic';
    static readonly CHEST_LAVA_IC: string = 'ch_lv_ic';

    static readonly WHEEL: string = 'cmp_wheel';
    static readonly WHEEL_BACK_BTN: string = 'wheel_back_btn';
    static readonly WHEEL_CURSOR: string = 'wheel_cursor';
    static readonly WHEEL_CURSOR_2: string = 'wheel_cursor_2';
    static readonly WHEEL_DECOR: string = 'wheel_decor';
    static readonly WHEEL_ROLL_BTN: string = 'wheel_roll_btn';
    static readonly WHEEL_SPLASH: string = 'wheel_splash';
    static readonly WHEEL_SPLASH_2: string = 'wheel_splash_2';
    static readonly WHEEL_WING: string = 'wheel_wing';
    static readonly WHEEL_PROGRESS: string = 'wheel_progress';
    static readonly TO_WHEEL_BTN: string = 'to_wheel_btn';
    static readonly WHEEL_SMALL: string = 'wheel_small';
    static readonly WHEEL_BG_BOTTOM: string = 'wheel_bg_bottom';
    static readonly HEALTH_TO_ALL: string = 'health_to_all';
    static readonly HEALTH_TO_ONE: string = 'health_to_one';
    static readonly ATTACK_TO_ALL: string = 'attack_to_all';
    static readonly DEFENCE_TO_ALL: string = 'defence_to_all';

    static readonly IC_100_ATTACK: string = 'ic_attack';
    static readonly IC_100_DEFENCE: string = 'ic_defence';
    static readonly IC_100_HEALTH: string = 'ic_heart';
    static readonly IC_100_GOLD: string = 'ic_gold';
    static readonly IC_100_ELIXIR: string = 'ic_elixir';
    static readonly IC_100_CRYSTAL: string = 'ic_crystal';
    static readonly IC_100_MITHRIL: string = 'ic_mithril';
    static readonly IC_110_MITHRIL: string = 'ic_mith_110';
    static readonly IC_100_AMETIST: string = 'ic_ametist';
    static readonly IC_100_SULFUR: string = 'ic_sulfur';
    static readonly IC_100_EXP: string = 'ic_exp';
    static readonly IC_100_KEY1: string = 'key1';
    static readonly IC_BRIGHT_CRYSTAL: string = 'ic_bright_crystal';
    static readonly IC_STATUE_1_TRIBUTE: string = 'ic_statue_1_tribute';
    static readonly IC_STATUE_2_TRIBUTE: string = 'ic_statue_2_tribute';
    static readonly IC_STATUE_3_TRIBUTE: string = 'ic_statue_3_tribute';
    static readonly IC_STATUE_4_TRIBUTE: string = 'ic_statue_4_tribute';
    static readonly IC_STATUE_5_TRIBUTE: string = 'ic_statue_5_tribute';
    static readonly IC_STATUE_6_TRIBUTE: string = 'ic_statue_6_tribute';
    static readonly IC_FLOWERS_1: string = 'ic_flowers_1';
    static readonly IC_MANDRAHORA: string = 'ic_mandrahora';
    static readonly IC_MUSHROOM_1: string = 'ic_mushroom_1';
    static readonly IC_LOUPE: string = 'loupe';
    static readonly IC_DRAGON_EGG: string = 'dragon_egg';
    static readonly IC_ELF_TRANDUIL: string = 'elf_tranduil';
    static readonly IC_PORTAL: string = 'portal';
    static readonly IC_RUNECRYSTAL_1: string = 'runecrystal_1';
    static readonly IC_RUNECRYSTAL_META: string = 'runecrystal_meta';
    static readonly IC_NECKLACE_1: string = 'necklace_1';
    static readonly IC_MEAT_PIE: string = 'meat-pie';
    static readonly IC_FRESH_MEAT_1: string = 'fresh-meat-1';
    static readonly IC_BERNIS_NO_SHADE: string = 'aunt-bernis-no-shade';
    static readonly IC_BILLY_NO_SHADE: string = 'billy-no-shade';
    static readonly IC_GLOOMBLOOM: string = 'ic_gloombloom';
    static readonly IC_DOOMBLOOM: string = 'ic_doombloom';
    static readonly IC_BALTUS_NO_SHADE: string = 'baltus-no-shade';
    static readonly IC_MINOTAUR: string = 'ic_minotaur';
    static readonly IC_BR_CUP: string = 'ic_br_cups';

    static readonly IMG_GREY_BACKGROUND: string = 'grey_background';
    static readonly IMG_SKULL_ICON: string = 'skull_icon';
    static readonly IMG_BATTLE_BACKGROUND: string = 'battle_background';
    static readonly IMG_ARENA_BACKGROUND: string = 'arena_background';
    static readonly IMG_PLAYER_TURN: string = 'your_turn';

    static readonly IMG_BLACK_BACKGROUND: string = 'black_background';

    static readonly IMG_LESSON_HAND: string = 'hand';
    static readonly IMG_LESSON_HAND_SHADOW: string = 'hand_shadow';

    static readonly IMG_CHAR_SELECTION_BACKGROUND: string = 'char_selection_back';
    static readonly IMG_CHAR_SELECTION_WINDOW: string = 'select_char_window';

    static readonly IMG_CARD_SHADE: string = 'card_shade';

    static readonly AGE_12: string = 'age_12';
    static readonly IMG_LOAD_SCREEN_BACKGROUND: string = 'load_screen_background';
    static readonly IMG_LOAD_SCREEN_TITLE_EN: string = 'load_title_en';
    static readonly IMG_LOAD_SCREEN_TITLE_RU: string = 'load_screen_title';
    static readonly IMG_LOAD_SCREEN_BAR_OUTER: string = 'load_screen_bar_outer';
    static readonly IMG_LOAD_SCREEN_BAR_INNER: string = 'load_screen_bar_inner';

    static readonly UI_MENU_ROAD: string = 'road-button';

    static readonly UI_LANG_BUTT: string = 'lang_butt';
    static readonly UI_LANG_ACC_BUTT: string = 'lang_acc_butt';

    static readonly UI_LANG_BUTT_AVAL: string = 'lang_butt_aval';
    static readonly UI_LANG_BUTT_UNAVAL: string = 'lang_butt_unaval';

    static readonly UI_LOGIN_BUTT: string = 'login_butt';
    static readonly UI_LOGIN_BUTT_BG: string = 'login_butt_bg';

    static readonly UI_ART_SLOT: string = 'slot';
    static readonly UI_ART_SLOT_DARK: string = 'slot-dark';
    static readonly UI_ART_SLOT_LOCKED: string = 'slot-locked';
    static readonly UI_ART_SLOT_UNLOCK: string = 'slot-unlock';

    static readonly UI_BOTTOM_MENU: string = 'bott_menu';

    static readonly UI_LEVEL_UP: string = 'levelUp';

    static readonly UI_ART_BASIC_WINDOW: string = 'art_window_basic';
    static readonly UI_ART_WINDOW_FORCES: string = 'art_window_forces';
    static readonly UI_WINDOW_HORIZONTAL: string = 'art_window_hor';
    static readonly UI_ART_X: string = 'x';
    static readonly UI_ART_CORNER_LEFT: string = 'corner-left';
    static readonly UI_ART_CORNER_DIAG: string = 'corner-diag';
    static readonly UI_ART_ARR: string = 'arr';
    static readonly UI_ART_TIME_ICON: string = 'timeIcon';
    static readonly UI_ART_LIGHT: string = 'light';
    static readonly P6_RU: string = 'p6ru';
    static readonly P7_RU: string = 'p7ru';
    static readonly P6_EN: string = 'p6en';
    static readonly P7_EN: string = 'p7en';
    static readonly UI_ART_GREEN_BUTTON: string = 'button-green';
    static readonly UI_ART_RED_BUTTON: string = 'button-red';
    static readonly UI_ART_PALE_BUTTON: string = 'button-pale';
    static readonly UI_ART_BROWN_BUTTON: string = 'button-brown';
    static readonly UI_ART_UPPER_ICON: string = 'upper';
    static readonly UI_ART_DECREASE_ICON: string = 'decrease';
    static readonly UI_ART_UPPER_ICON_GREEN: string = 'upper-green';
    static readonly UI_ART_TAKE_OFF: string = 'take-off';
    static readonly UI_ART_SLOT_LIGHT: string = 'slot-light';

    static readonly UI_WINDOW_MIDDLE: string = 'wind_mid';
    static readonly UI_WINDOW_MIDDLE_BIG: string = 'wind_mid_BIG';
    static readonly UI_WINDOW_BIG: string = 'wind_big';

    static readonly UI_CHECK_SIGN: string = 'check';
    static readonly UI_CROSS_SIGN: string = 'cross';
    static readonly UI_SQ_BUTTON: string = 'sq_button';

    static readonly UI_BACKPACK_ICON: string = 'backpack-icon';
    static readonly UI_MANUAL_ICON: string = 'manual-icon';
    static readonly UI_BACKPACK_WINDOW: string = 'backpack-window';
    static readonly UI_BACKPACK_INFO: string = 'backpack-info-panel';
    static readonly UI_BACKPACK_SLOTS_ICON: string = 'backpack-slots-icon';
    static readonly UI_BACKPACK_CORNER_SHADOW: string = 'backpack-corner-shadow';

    static readonly UI_DISCOUNT_ENABLED: string = 'discount-enabled';
    static readonly UI_DISCOUNT_DISABLED: string = 'discount-disabled';

    static readonly UI_POWER_UP_BG_3: string = 'power-up-bg-3';
    static readonly UI_POWER_UP_ARROW: string = 'power-up-arrow';
    static readonly UI_POWER_UP_INFO_TOP: string = 'power-up-info-top';
    static readonly UI_POWER_UP_INFO_BOTTOM: string = 'power-up-info-bottom';
    static readonly UI_POWER_UP_INFO_MIDDLE: string = 'power-up-info-middle';

    static readonly UI_MANUAL_QUESTION_GREEN: string = 'manual-question-green';
    static readonly UI_MANUAL_BLUE_FADE: string = 'manual-blue-fade';
    static readonly UI_MANUAL_TOP_FRAME: string = 'manual-top-frame';

    static readonly UI_FORCES_BLACK_BG : string = 'force-black-bg';
    static readonly UI_FORCES_GLASS_BG : string = 'force-glass-bg';
    static readonly UI_FORCES_BLACK_ZONE : string = 'black-zone';
    static readonly UI_FORCES_BG_GOLD: string = 'bg-gold';
    static readonly UI_FORCES_INDICATOR_AVAILABLE: string = 'indicator-available';
    static readonly UI_FORCES_INDICATOR_UNAVAILABLE: string = 'indicator-unavailable';
    static readonly UI_FORCES_MAIN_HIGHLIGHT: string = 'force-main-highlight';
    static readonly UI_FORCES_HAMMER: string = 'hammer';
    static readonly UI_FORCES_HAMMER_G: string = 'hammer-g';
    static readonly UI_FORCES_HAMMER_BG: string = 'hummer-bg';
    static readonly UI_FORCES_HAMMER_PLUS: string = 'hummer-plus';
    static readonly UI_FORCES_INDICATOR_BG: string = 'indicator-bg';
    static readonly UI_FORCES_INDICATOR_CAN_UPGRADE: string = 'indicator-can-upgrade';
    static readonly UI_FORCES_INDICATOR_UPGRADE_IN_PROGRESS: string = 'indicator-upgrade-in-progress';
    static readonly UI_FORCES_TAB_ACTIVE: string = 'tab-active';
    static readonly UI_FORCES_TAB_INACTIVE: string = 'tab-inactive';

    static readonly GREEN_HORIZONTAL_BUTTON: string = 'gr_hor_butt';

    static readonly EDU_BLACKOUT: string = 'edu_blackout';
    static readonly EDU_TEACHER: string = 'edu_girl';
    static readonly EDU_CAT: string = 'edu_cat';

    static readonly PACK_PANEL_BACK: string = 'pack-back';
    static readonly PACK_SILVER: string = 'gems-8';
    static readonly PACK_GOLDEN: string = 'gems-9';
    static readonly PACK_DOMINATOR: string = 'gems-10';
    static readonly PACK_GOD: string = 'gems-11';
    static readonly PACK_LIGHTING: string = 'pack-lighting';
    static readonly PACK_SEP: string = 'pack-sep';
    static readonly PACK_SEP_LONG: string = 'pack-sep-long';

    static readonly UI_SHOP_PANEL_BACK: string = 'shop-back';
    static readonly UI_SHOP_LIGHT: string = 'shop-light';
    static readonly UI_SHOP_SEPARATOR: string = 'shop-separator';
    static readonly UI_SHOP_SEPARATOR_LARGE: string = 'separator-large';
    static readonly UI_SHOP_X: string = 'shop-x';
    static readonly UI_SHOP_TAB_ACTIVE: string = 'shop-tab-active';
    static readonly UI_SHOP_TAB_INACTIVE: string = 'shop-tab-inactive';
    static readonly UI_SHOP_TITLE_LIGHT: string = 'shop-title-light';
    static readonly PLAY_REWARDED: string = 'play';
    static readonly UI_SHOP_GEMS_0: string = 'gems-0';
    static readonly UI_SHOP_GEMS_1: string = 'gems-1';
    static readonly UI_SHOP_GEMS_2: string = 'gems-2';
    static readonly UI_SHOP_GEMS_3: string = 'gems-3';
    static readonly UI_SHOP_GEMS_4: string = 'gems-4';
    static readonly UI_SHOP_GEMS_5: string = 'gems-5';
    static readonly UI_SHOP_GEMS_6: string = 'gems-6';
    static readonly UI_SHOP_GEMS_7: string = 'gems-7';
    static readonly UI_SHOP_ARTIFACT_EFFECT_DEFENCE_ICON: string = 'shop_item_effect_def';
    static readonly UI_SHOP_ARTIFACT_EFFECT_ATTACK_ICON: string = 'shop_item_effect_atk';
    static readonly UI_SHOP_ARTIFACT_EFFECT_HEALTH_ICON: string = 'shop_item_effect_hlth';
    static readonly UI_SHOP_PAGE_LOCK: string = 'shop_page_lock';

    static readonly UI_INFO: string = 'info';

    static readonly UI_WARNING: string = 'warning_icon';

    static readonly CLAN_RATING: string = 'clan_rating';
    static readonly CLAN_BUILDING: string = 'clan_building';
    static readonly CLAN_ONLINE: string = 'clan_online';
    static readonly CLAN_OFFLINE: string = 'clan_offline';
    static readonly CLAN_GEAR: string = 'clan_gear';
    static readonly CLAN_GEAR_2: string = 'clan_gear_2';
    static readonly CLAN_GEAR_3: string = 'clan_gear_3';
    static readonly CLAN_GEAR_4: string = 'clan_gear_4';

    static readonly CL_INVITE: string = 'cl_invite';
    static readonly CL_KICK: string = 'cl_kick';
    static readonly CL_UP: string = 'cl_up';
    static readonly CL_DOWN: string = 'cl_down';
    static readonly CL_FORUM: string = 'cl_forum';

    static readonly CL_ABOUT: string = 'cl_about';
    static readonly CL_BUDGET: string = 'cl_budget';
    static readonly CL_BUILD: string = 'cl_build';
    static readonly CL_CHAT: string = 'cl_chat';
    static readonly CL_FAME: string = 'cl_fame';
    static readonly CL_HEADER: string = 'cl_header';
    static readonly CL_MEMEBERS: string = 'cl_members';
    static readonly CL_ADVERTISEMENT: string = 'cl_advertisement';
    static readonly CL_OTHER: string = 'cl_other';
    static readonly CL_RAID: string = 'cl_raid';
    static readonly CL_RATING: string = 'cl_rating';
    static readonly CL_SHOP: string = 'cl_shop';
    static readonly CL_RECRUITING: string = 'cl_recruiting';

    static readonly CLAN_BACK: string = 'clan_back_';
    static readonly CLAN_THEME_BORDER: string = 'cl_th_bord';
    static readonly CLAN_THEME_PREFIX: string = 'cl_th_';

    static readonly CL_HAMMER: string = 'cl_ham';
    static readonly CL_ACADEMY: string = 'cl_academy';
    static readonly CL_GUILD: string = 'cl_guild';
    static readonly CL_LAB: string = 'cl_lab';
    static readonly CL_FACTORY: string = 'cl_factory';
    static readonly CL_HALL: string = 'cl_hall';
    static readonly CL_SNOWFLAKE: string = 'cl_snflk';

    static readonly CL_SMALL_RED_BUTTON: string = 'small-red-button';
    static readonly CL_BLACK_SQUARE: string = 'black-square';
    static readonly CL_GEAR_RESOURCE: string = 'clan-gear-resource';

    static readonly HINT_BUTTON: string = 'hint_button';

    static readonly OFFER_RIBBON: string = 'offer_ribbon';
    static readonly OFFER_RIBBON_EN: string = 'offer_ribbon_en';
    static readonly CAMPAIGN_RIBBON: string = 'ribbon_orange';
    static readonly OFFER_LIGHT_BACK: string = 'light_back';
    static readonly OFFER_SHOP_ICON: string = 'shop_icon';
    static readonly OFFER_RIBBON_FAT: string = 'ribbon_fat';
    static readonly CAMPAIGN_RIBBON_BLUE: string = 'ribbon_blue';
    static readonly RIBBON_FOR_BUNCH: string = 'ribbon_for_bunch';
    static readonly BATTLE_TOP_BOTTOM: string = 'batt_tp_bt';

    public static readonly MAIN_HERO_MAN = 'man-1';
    public static readonly MAIN_HERO_WOMAN = 'woman-1';

    public static readonly IMG_MAIN_HERO_MALE_CAT: string = 'male-cat-2';
    public static readonly IMG_MAIN_HERO_FEMALE_CAT: string = 'female-cat-2';

    static readonly REPLICS: string[] = ['dark-mage-face', 'dark-gol', 'ice-gol', 'rock-gol']

    static readonly ATLAS_PROFILE: string = 'atlas_prof';
    static readonly ATLAS_PRELOAD: string = 'atlas_prel';

    static readonly ATLAS_SKILLS: string = 'atlas_skills';
    static readonly SK_ATTACK: string = 'sk_attack';
    static readonly SK_DEFENCE: string = 'sk_defence';
    static readonly SK_HEALTH: string = 'sk_health';

    static readonly ATLAS_GIFTS: string = 'atlas_gifts';
    static readonly ATLAS_GIFTS_2: string = 'atlas_gifts_2';
    static readonly ATLAS_GIFTS_3: string = 'atlas_gifts_3';
    static readonly ATLAS_GIFTS_4: string = 'atlas_gifts_4';
    static readonly ATLAS_GIFTS_5: string = 'atlas_gifts_5';
    static readonly ATLAS_GIFTS_6: string = 'atlas_gifts_6';
    static readonly ATLAS_GIFTS_7: string = 'atlas_gifts_7';
    static readonly ATLAS_GIFTS_8: string = 'atlas_gifts_8';

    static readonly ATLAS_UI_FORCES: string = 'atlas_ui_forces';
    static readonly ATLAS_FORCES: string = 'atlas_forces';
    static readonly ATLAS_FORCES_6: string = 'atlas_forces_6';
    static readonly ATLAS_FORCES_BIG: string = 'atlas_forces_big';
    static readonly ATLAS_FORCES_BIG_6: string = 'atlas_forces_big_6';
    static readonly ATLAS_FORCES_LARGE: string = 'atlas_forces_large';
    static readonly ATLAS_FORCES_LARGE_2: string = 'atlas_forces_large_2';
    static readonly ATLAS_FORCES_LARGE_3: string = 'atlas_forces_large_3';
    static readonly ATLAS_FORCES_LARGE_6: string = 'atlas_forces_large_6';
    static readonly ATLAS_FORCES_SEMI_LARGE: string = 'atlas_forces_semi_large';
    static readonly ATLAS_FORCES_SEMI_LARGE_6: string = 'atlas_f_s_l6';

    static readonly ATLAS_FORCES_2: string = 'atlas_forces_2';
    static readonly ATLAS_FORCES_BIG_2: string = 'atlas_forces_big_2';

    static readonly ATLAS_FORCES_5: string = 'atlas_forces_5';
    static readonly ATLAS_FORCES_BIG_5: string = 'atlas_forces_big_5';

    static readonly ATLAS_CREATURES_BATTLE_0: string = 'atlas_cr_battle_0';
    static readonly ATLAS_CREATURES_BATTLE_1: string = 'atlas_cr_battle_1';
    static readonly ATLAS_CREATURES_ROAD: string = 'atlas_cr_road';

    static readonly ATLAS_EDU: string = 'atlas_edu';
    static readonly ATLAS_EDU_2: string = 'atlas_edu_2';
    static readonly ATLAS_EDU_3: string = 'atlas_edu_3';
    static readonly ATLAS_EDU_6: string = 'atlas_edu_6';
    static readonly ATLAS_EDU_SDQ: string = 'atlas_edu_sdq';

    static readonly ATLAS_PLACES_1: string = 'atlas_places_1';
    static readonly ATLAS_SUPERSHOP: string = 'Supershop';
    static readonly ATLAS_IC_100: string = 'atlas_ic_100';
    static readonly ATLAS_BADGES: string = 'atlas_badge';
    static readonly ATLAS_POINTERS: string = 'atlas_pointers';
    static readonly ATLAS_CONSUMABLES_SHADE: string = 'atlas_cons_shade';
    static readonly ATLAS_CONSUMABLES_NO_SHADE: string = 'atlas_cons_no_shade';
    static readonly ATLAS_GAINABLE: string = 'atlas_gain';
    static readonly ATLAS_GAINABLE_2: string = 'atlas_gain_2';
    static readonly ATLAS_PLACES_BIRTHDAY: string = 'atlas_birthday';
    static readonly ATLAS_RUNES: string = 'runes';
    static readonly ATLAS_RUNES_4: string = 'runes4';
    static readonly ATLAS_UI: string = 'atlas_ui';

    static readonly ATLAS_UI_2: string = 'atlas_ui_2';

    static readonly ATLAS_PORTALS: string = 'atlas_portal';

    static readonly PORTAL_P1: string = 'P1';
    static readonly PORTAL_P2: string = 'P2';
    static readonly PORTAL_P3: string = 'P3';
    static readonly PORTAL_P4: string = 'P4';
    static readonly PORTAL_P5: string = 'P5';
    static readonly PORTAL_P6: string = 'P6';
    static readonly PORTAL_P7: string = 'P7';
    static readonly PORTAL_P8: string = 'P8';
    static readonly PORTAL_P9: string = 'P9';
    static readonly PORTAL_P10: string = 'P10';
    static readonly PORTAL_PANEL_BACK: string = 'port_back';
    static readonly PORTAL_PANEL_LIGHT: string = 'port_light';


    static readonly ATLAS_POINTERS_ADD: string = 'atlas_pointers_add';
    static readonly ATLAS_POINTERS_ADD_2: string = 'atlas_pointers_add_2';

    static readonly ROADS: string[] = ['road_forrest', 'road_fire',
        'road_village', 'road_elves', 'road_winter', 'asg_straight', 'water_straight', 'water_strip', 'asg_strip'];

    static readonly ROADS_WEBP: string[] = ['atlant_straight', 'atlant_strip', 'dream_straight', 'dream_strip',
        'heaven_straight', 'heaven_strip', 'ghost_straight', 'ghost_strip', 'ny_straight', 'ny_strip',
        'p6_cave_straight', 'p6_cave_strip', 'p6_parallel_straight', 'p6_parallel_strip',
        'p7_cap_straight', 'p7_cap_strip', 'zone_forrest_straight', 'zone_forrest_strip'];

    static readonly ROADS_2: string[] = ['road_sand', 'sand_turn_left'];

    static readonly ROADS_START = 'road_default';
    static readonly ROADS_START_2 = 'main_straight';

    static readonly ROADS_UI: string[] = ['road_default',
        'road_turn_right', 'road_turn_left', 'road_turn_left_fire', 'road_turn_right_vill',
        'road_turn_left_elves'];

    static readonly ZONES: string[] = ['phoenix_town'];

    static readonly ROAD_MAIN: string[] = ['main_straight', 'main_strip',
        'main_right', 'main_left', 'main_fire', 'main_vill', 'main_elf'];

    static readonly ATLAS_ATL_AVA: string = 'atlas_atl_ava';
    static readonly ATLAS_CREATURES_BATTLE_2: string = 'atlas_cr_battle_2';
    static readonly ATLAS_CREATURES_BATTLE_4: string = 'atlas_cr_battle_4';
    static readonly ATLAS_CREATURES_BATTLE_5: string = 'atlas_cr_battle_5';
    static readonly ATLAS_CREATURES_BATTLE_8: string = 'atlas_cr_battle_8';
    static readonly ATLAS_CREATURES_BATTLE_SCEN_8: string = 'atlas_cr_battle_s8';
    static readonly ATLAS_HERO_8: string = 'atlas_hero_8';
    static readonly ATLAS_OF: string = 'atlas_of';
    static readonly ATLAS_RANK: string = 'atlas_rank';
    static readonly ATLAS_CREATURES_BATTLE_7: string = 'atlas_cr_battle_7';
    static readonly ATLAS_BATTLE_CATS: string = 'atlas_cats_b';
    static readonly ATLAS_BATTLE_AM: string = 'atlas_am';
    static readonly ATLAS_BATTLE_WT: string = 'atlas_wt';
    static readonly ATLAS_ANGELS: string = 'atlas_angels';
    static readonly ATLAS_TRIPLE_BATTLES: string = 'atlas_triple_battles';
    static readonly ATLAS_SIDE_QUESTS: string = 'atlas_side_quests';
    static readonly ATLAS_ROAD_CATS: string = 'atlas_cats_r';
    static readonly ATLAS_CREATURES_ROAD_2: string = 'atlas_cr_road_2';
    static readonly ATLAS_CREATURES_ROAD_4: string = 'atlas_cr_road_4';
    static readonly ATLAS_CREATURES_ROAD_5: string = 'atlas_cr_road_5';
    static readonly ATLAS_CREATURES_ROAD_8: string = 'atlas_cr_road_8';
    static readonly ATLAS_PLACES_2: string = 'atlas_places_2';
    static readonly ATLAS_PLACES_3: string = 'atlas_places_3';
    static readonly ATLAS_PLACES_4: string = 'atlas_places_4';
    static readonly ATLAS_PLACES_5: string = 'atlas_places_5';
    static readonly ATLAS_PLACES_6: string = 'atlas_places_6';
    static readonly ATLAS_PLACES_7: string = 'atlas_places_7';
    static readonly ATLAS_PLACES_8: string = 'atlas_places_8';
    static readonly ATLAS_PLACES_9: string = 'atlas_places_9';
    static readonly ATLAS_PLACES_10: string = 'atlas_places_10';
    static readonly ATLAS_PLACES_11: string = 'atlas_places_11';
    static readonly ATLAS_PLACES_12: string = 'atlas_places_12';
    static readonly ATLAS_PLACES_13: string = 'atlas_places_13';
    static readonly ATLAS_PLACES_NY: string = 'places_ny';
    static readonly ATLAS_PLACES_ZONES_1: string = 'atlas_zones_1';
    static readonly ATLAS_ZONE_DRUID: string = 'atlas_zones_zrd';
    static readonly ATLAS_PLACES_TRIPLE: string = 'atlas_places_triple';
    static readonly ATLAS_PLACES_WATER: string = 'places-nps';
    static readonly ATLAS_PLACES_SIDE_QUESTS: string = 'places-sdq';
    static readonly ATLAS_POINTERS_2: string = 'atlas_pointers_2';
    static readonly ATLAS_POINTERS_3: string = 'atlas_pointers_3';
    static readonly ATLAS_POINTERS_4: string = 'atlas_pointers_4';
    static readonly ATLAS_POINTERS_SIDE_QUESTS: string = 'atlas_pointers_sdq';
    static readonly ATLAS_CONSUMABLES_SHADE_2: string = 'atlas_cons_shade_2';
    static readonly ATLAS_CONSUMABLES_NO_SHADE_2: string = 'atlas_cons_no_shade_2';
    static readonly ATLAS_CONSUMABLES_SHADE_4: string = 'atlas_cons_shade_4';
    static readonly ATLAS_CONSUMABLES_NO_SHADE_4: string = 'atlas_cons_no_shade_4';
    static readonly ATLAS_CONSUMABLES_SHADE_4X: string = 'atlas_cons_shade_4x';
    static readonly ATLAS_CONSUMABLES_NO_SHADE_4X: string = 'atlas_cons_no_shade_4x';
    static readonly ATLAS_CONS_SHADE_6: string = 'atl_cns_shd_6';
    static readonly ATLAS_CONS_NO_SHADE_6: string = 'atl_cns_no_shd_6';
    static readonly ATLAS_BOOST: string = 'atlas_boost';
    static readonly ATLAS_BANK: string = 'atlas_bank';
    static readonly ATLAS_BANK_2: string = 'atlas_bank_2';
    static readonly ATLAS_SHOP_UI: string = 'atlas_shop_ui';

    static readonly ADD_MUSHROOM: string = 'add-mushroom';

    static readonly ENEMY_LEV_5: string = 'enemy_5';
    static readonly BIRTHDAY_HATS: string[] = ['hat-1', 'hat-3', 'hat-5', 'hat-6', 'hat-7', 'hat-8',  'hat-10', 'hat-2', 'hat-4', 'hat-9'];
    static readonly BIRDS_FOR_MUSHROOM: string[] = ['sdq-bird1', 'sdq-bird2', 'sdq-bird3'];
    static readonly FLOWERS_FOR_WREATH: string[] = ['sdq-flower1', 'sdq-flower3'];

    // Make an atlas later:
    static readonly ADDITIONAL_GAINABLES: string[] = ['task-fern', 'task-books', 'task-apple', 'life_bush', 'mirmaid_tail', 'task-pumpkin',
    'sdq-bird1', 'sdq-bird2', 'sdq-bird3', 'sdq-black-stone', 'find_saint_rock_for_statue', 'sdq-flower1', 'sdq-flower3', 'sdq-stem-floral', 'chickens', 'invis', 'nps-scale',
        'collect_map_parts', 'find_cavirals'];

    static readonly ADDITIONAL_CREATURES: string[] = ['squirrel', 'battle-teapot-1','battle-teapot-2','battle-teapot-3', 'battle-card-1','battle-card-2','battle-card-3',
        'nps-barbarian-1', 'nps-barbarian-2', 'nps-barbarian-3', 'nps-fishman', 'kiki',
        'kupala-beast-1', 'kupala-beast-2', 'kupala-beast-3', 'defiler_red', 'defiler_blue', 'defiler_green',  'tree', 'luckydrag', 'kitty', 'dark_serra', 'taitus', 'cow_1', 'cow_2', 'hed_hog'];

    // Make an atlas later:
    static readonly ADDITIONAL_PLACES: string[] = ['forge', 'spring_elf', 'witch_1', 'yarilo', 'barrels', 'kupala-bonfire', 'cat',
        'ev_caterpillar', 'ev_cat', 'wings', 'feather',
        'p5_cube', 'ev_vamp', 'feast1', 'feast2', 'feast3', 'ev_byaka', 'cat_hole', 'cat_male', 'cat_female', 'goblin1', 'farm', 'pic_golem_in_the_field',
        'brain', 'carrot', 'dragon_egg', 'hole', 'sun', 'fox_and_scroll'];

    static readonly PROFILE_CREATURES: string[] = [Keys.MAIN_HERO_MAN, Keys.MAIN_HERO_WOMAN,
                                                   Keys.IMG_MAIN_HERO_MALE_CAT, Keys.IMG_MAIN_HERO_FEMALE_CAT];

    static readonly LOADING_ANIM: string = 'loading_anim';
    static readonly ANIM_BIRD_FLIGHT: string = 'bird';
    static readonly ANIM_BLUE_BIRD_FLIGHT: string = 'bird_blue';

    static readonly ANIMS: string[] = ['dark_golem', 'ice_golem', 'rock_golem', Keys.LOADING_ANIM, 'seth-ent', Keys.ANIM_BIRD_FLIGHT, Keys.ANIM_BLUE_BIRD_FLIGHT];

    static battleCreature(creatureId: string): string {
        return 'b_' + creatureId;
    }

    static profileCreature(creatureId: string): string {
        return 'p_' + creatureId;
    }

}
