import {BalanceListener, Listener} from "@/store/listener";
import {UpdateListener} from "@/utils/update-listener";
import {ClanShortInfo} from "@/store/clan/types";

export default class ListenerManager {

    private static playerListeners: Listener[] = [];

    private static balanceListeners: BalanceListener[] = [];

    private static sessionCreateCallback: () => unknown;

    private static updateListeners: UpdateListener[] = [];

    private static registerCallback: () => unknown;

    private static beelineRegisterCallback: () => unknown;

    private static loginCallback: () => unknown;

    private static ratingCallback: () => unknown;

    private static clanRatingCallback: () => unknown;

    private static chatOpenCallback: (chatRoom: string) => unknown;

    private static mailCallback: () => unknown;

    private static profileCallback: (id: number) => unknown;

    private static clanDetailsCallback: (ci: ClanShortInfo) => unknown;

    private static loginSuccessfulCallback: () => unknown;

    private static connectionLostCallback: () => unknown;

    private static chatSeenCallback: (notSeen: boolean) => unknown;

    private static okGemsCallback: () => unknown;

    private static renamePageOpenCallback: () => unknown;
    private static changeEmailOrPhoneCallback: () => unknown;
    private static passwordPageOpenCallback: () => unknown;

    private static clanNamePageOpenCallback: () => unknown;

    private static clanAboutPageOpenCallback: () => unknown;
    private static clanAboutPageCloseCallback: () => unknown;

    private static editPageOpenCallback: () => unknown;
    private static editPageCloseCallback: () => unknown;

    private static clanSceneCallback: () => unknown;

    private static clanInfoListeners: Listener[] = [];

    private static openChestPanelCallback: () => unknown;
    private static closeChestPanelCallback: () => unknown;

    private static openTopicCallback: (topicId: number) => unknown;

    private static closeChatCallback: () => unknown;

    public static setCloseChatCallback(closeChatCallback: () => unknown) {
        ListenerManager.closeChatCallback = closeChatCallback;
    }

    public static callCloseChatCallback() {
        if (ListenerManager.closeChatCallback) {
            ListenerManager.closeChatCallback();
        }
    }

    public static setOpenTopicCallback(openTopicCallback: (topicId: number) => unknown) {
        ListenerManager.openTopicCallback = openTopicCallback;
    }

    public static callOpenTopicCallback(topicId: number) {
        if (ListenerManager.openTopicCallback) {
            ListenerManager.openTopicCallback(topicId);
        }
    }

    public static setSessionCreateCallback(sessionCreateCallback: () => unknown): void {
        ListenerManager.sessionCreateCallback = sessionCreateCallback;
    }

    public static callSessionCreateCallback(): void {
        if (ListenerManager.sessionCreateCallback) {
            ListenerManager.sessionCreateCallback();
        }
    }

    public static setRatingCallback(ratingCallback: () => unknown): void {
        ListenerManager.ratingCallback = ratingCallback;
    }

    public static setClanRatingCallback(clanRatingCallback: () => unknown): void {
        ListenerManager.clanRatingCallback = clanRatingCallback;
    }

    public static callRatingCallback(): void {
        ListenerManager.ratingCallback();
    }

    public static callClanRatingCallback(): void {
        ListenerManager.clanRatingCallback();
    }

    public static setChatOpenCallback(chatOpenCallback: (chatRoom: string) => unknown): void {
        ListenerManager.chatOpenCallback = chatOpenCallback;
    }

    public static callChatOpenCallback(chatRoom: string): void {
        ListenerManager.chatOpenCallback(chatRoom);
    }

    public static setMailCallback(mailCallback: () => unknown): void {
        ListenerManager.mailCallback = mailCallback;
    }

    public static callMailCallback(): void {
        ListenerManager.mailCallback();
    }

    public static setRegisterCallback(registerCallback: () => unknown): void {
        ListenerManager.registerCallback = registerCallback;
    }

    public static callRegisterCallback(): void {
        ListenerManager.registerCallback();
    }

    public static setBeelineRegisterCallback(beelineRegisterCallback: () => unknown): void {
        ListenerManager.beelineRegisterCallback = beelineRegisterCallback;
    }

    public static callBeelineRegisterCallback(): void {
        ListenerManager.beelineRegisterCallback();
    }

    public static setLoginCallback(loginCallback: () => unknown): void {
        ListenerManager.loginCallback = loginCallback;
    }

    public static callLoginCallback(): void {
        if (ListenerManager.loginCallback) {
            ListenerManager.loginCallback();
        }
    }

    public static setLoginSuccessfulCallback(loginSuccessfulCallback: () => unknown): void {
        ListenerManager.loginSuccessfulCallback = loginSuccessfulCallback;
    }

    public static callLoginSuccessfulCallback(): void {
        if (ListenerManager.loginSuccessfulCallback) {
            ListenerManager.loginSuccessfulCallback();
        }
    }

    public static setProfileCallback(profileCallback: (playerId: number) => unknown): void {
        ListenerManager.profileCallback = profileCallback;
    }

    public static callProfileCallback(id: number): void {
        ListenerManager.profileCallback(id);
    }

    public static setClanDetailsCallback(clanDetailsCallback: (ci: ClanShortInfo) => unknown): void {
        ListenerManager.clanDetailsCallback = clanDetailsCallback;
    }

    public static callClanDetailsCallback(ci: ClanShortInfo): void {
        ListenerManager.clanDetailsCallback(ci);
    }

    public static addBalanceListener(bl: BalanceListener): void {
        if (!ListenerManager.balanceListeners.includes(bl)) {
            ListenerManager.balanceListeners.push(bl);
        }
    }

    public static addPlayerListener(playerListener: Listener): void {
        if (!ListenerManager.playerListeners.includes(playerListener)) {
            ListenerManager.playerListeners.push(playerListener);
        }
    }

    public static removePlayerListener(playerListener: Listener): void {
        if (ListenerManager.playerListeners.includes(playerListener)) {
            const index = ListenerManager.playerListeners.indexOf(playerListener, 0);
            if (index > -1) { ListenerManager.playerListeners.splice(index, 1); }
        }
    }

    public static getPlayerListeners(): Listener[] {
        return ListenerManager.playerListeners;
    }

    public static getBalanceListeners(): BalanceListener[] {
        return ListenerManager.balanceListeners;
    }

    public static addUpdateListener(updateListener: UpdateListener): void {
        if (!ListenerManager.updateListeners.includes(updateListener)) {
            ListenerManager.updateListeners.push(updateListener);
        }
    }

    public static removeUpdateListener(updateListener: UpdateListener): void {
        if (ListenerManager.updateListeners.includes(updateListener)) {
            const index = ListenerManager.updateListeners.indexOf(updateListener, 0);
            if (index > -1) { ListenerManager.updateListeners.splice(index, 1); }
        }
    }

    public static getUpdateListeners(): UpdateListener[] {
        return ListenerManager.updateListeners;
    }

    public static callUpdateListeners() {
        ListenerManager.updateListeners.forEach(l => l.update());
    }

    public static setConnectionLostCallback(connectionLostCallback: () => unknown): void {
        ListenerManager.connectionLostCallback = connectionLostCallback;
    }

    public static callConnectionLostCallback(): void {
        if (ListenerManager.connectionLostCallback) {
            ListenerManager.connectionLostCallback();
        }
    }

    public static setChatSeenCallback(chatSeenCallback: (notSeen: boolean) => unknown): void {
        ListenerManager.chatSeenCallback = chatSeenCallback;
    }

    public static callChatSeenCallback(notSeen: boolean): void {
        if (ListenerManager.chatSeenCallback) {
            ListenerManager.chatSeenCallback(notSeen);
        }
    }


    public static setOkGemsCallback(okGemsCallback: () => unknown): void {
        ListenerManager.okGemsCallback = okGemsCallback;
    }

    public static callOkGemsCallback(): void {
        if (!!ListenerManager.okGemsCallback) {
            ListenerManager.okGemsCallback();
        }
    }

    public static setRenamePageOpenCallback(renamePageOpenCallback: () => unknown): void {
        ListenerManager.renamePageOpenCallback = renamePageOpenCallback;
    }

    public static setChangeEmailOrPhonePageOpenCallback(changeEmailOrPhoneCallback: () => unknown): void {
        ListenerManager.changeEmailOrPhoneCallback = changeEmailOrPhoneCallback;
    }
    public static setPasswordPageOpenCallback(passwordPageOpenCallback: () => unknown): void {
        ListenerManager.passwordPageOpenCallback = passwordPageOpenCallback;
    }

    public static callRenamePageOpenCallback(): void {
        ListenerManager.renamePageOpenCallback();
    }

    public static callChangeEmailOrPhoneCallback(): void {
        ListenerManager.changeEmailOrPhoneCallback();
    }

    public static callPasswordPageOpenCallback(): void {
        ListenerManager.passwordPageOpenCallback();
    }

    public static setClanNamePageOpenCallback(clanNamePageOpenCallback: () => unknown): void {
        ListenerManager.clanNamePageOpenCallback = clanNamePageOpenCallback;
    }

    public static callClanNamePageOpenCallback(): void {
        ListenerManager.clanNamePageOpenCallback();
    }

    public static setClanAboutPageOpenCallback(clanAboutPageOpenCallback: () => unknown): void {
        ListenerManager.clanAboutPageOpenCallback = clanAboutPageOpenCallback;
    }

    public static callClanAboutPageOpenCallback(): void {
        ListenerManager.clanAboutPageOpenCallback();
    }

    public static setEditPageOpenCallback(editPageOpenCallback: () => unknown): void {
        ListenerManager.editPageOpenCallback = editPageOpenCallback;
    }

    public static callEditPageOpenCallback(): void {
        ListenerManager.editPageOpenCallback();
    }

    public static setClanAboutPageCloseCallback(clanAboutPageCloseCallback: () => unknown): void {
        ListenerManager.clanAboutPageCloseCallback = clanAboutPageCloseCallback;
    }

    public static callClanAboutPageCloseCallback(): void {
        ListenerManager.clanAboutPageCloseCallback();
    }

    public static setEditPageCloseCallback(editPageCloseCallback: () => unknown): void {
        ListenerManager.editPageCloseCallback = editPageCloseCallback;
    }

    public static callEditPageCloseCallback(): void {
        ListenerManager.editPageCloseCallback();
    }

    public static setClanCreationCallback(clanSceneCallback: () => unknown): void {
        ListenerManager.clanSceneCallback = clanSceneCallback;
    }

    public static callClanCreationCallback(): void {
        ListenerManager.clanSceneCallback();
    }

    public static setOpenChestPanelCallback(openChestPanelCallback: () => unknown): void {
        ListenerManager.openChestPanelCallback = openChestPanelCallback;
    }

    public static callOpenChestPanelCallback(): void {
        if (ListenerManager.openChestPanelCallback) {
            ListenerManager.openChestPanelCallback();
        }
    }

    public static setCloseChestPanelCallback(closeChestPanelCallback: () => unknown): void {
        ListenerManager.closeChestPanelCallback = closeChestPanelCallback;
    }

    public static callCloseChestPanelCallback(): void {
        if (ListenerManager.closeChestPanelCallback) {
            ListenerManager.closeChestPanelCallback();
        }
    }

    public static addClanInfoListener(clanInfoListeners: Listener): void {
        if (!ListenerManager.clanInfoListeners.includes(clanInfoListeners)) {
            ListenerManager.clanInfoListeners.push(clanInfoListeners);
        }
    }

    public static getClanInfoListeners(): Listener[] {
        return ListenerManager.clanInfoListeners;
    }
}
